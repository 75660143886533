<template>
  <div class="content" v-if="pageSelect === 1">
    <div class="intro">
      <div class="inner-intro">
        <div class="menu">
          <div @click="pageSelect = 1" class="menu-logo">
            <img src="@/assets/logo-dark.png" alt="ADE platform" />
          </div>
          <div class="menu-links">
            <p @click="pageSelect = 1">Home</p>
            <p @click="pageSelect = 2">About Us</p>
            <p @click="pageSelect = 3">Legal</p>
          </div>
          <div class="mobile-menu-close" @click="openNav()">
            <img src="@/assets/Hamburger_icon.svg.png" alt="ADE platform" />
          </div>
        </div>
        <div class="intro-box-1">
          <div class="intro-box-1-left">
            <h1>Crypto Trading for Financial Institutions.</h1>
            <h2>
              <b>We recognize the unique needs of financial institutions</b>, and our platform is crafted to
              address those demands seamlessly. Whether you are a bank, hedge fund, or brokerage,
              <b>Adria Digital Exchange provides a comprehensive suite of services</b>, empowering you to harness
              the potential of digital assets within the bounds of regulatory compliance.
            </h2>
            <h2>
              Our goal is to bring together traditional <b>MiFID II</b> financial instruments and <b>distributed ledger
                technologies (DLT)</b>, unlocking the full potential of DLT not only in operational and security sense,
              but also enhancing current regulatory requirements.
            </h2>
          </div>
          <div class="intro-box-1-right">
            <div class="placholder-slika">
              <img src="@/assets/graphUp.png" alt="ADE platform" />
            </div>
          </div>
        </div>
        <div class="intro-box-2">
          <div class="intro-box-2-row-moto">
            <h2><span>Adria Digital Exchange</span> - Securing The Future of Regulated Trading. Today.</h2>
          </div>
          <div class="intro-box-2-row-1">
            <p class="partners">Our partners</p>
          </div>
          <div class="intro-box-2-row-2">
            <div class="intro-logo-box">
              <a href="https://zse.hr/" target="_blank">
                <div class="logo-placeholder">
                  <img src="@/assets/zb-mono.png" alt="zagrebacka burza" />
                </div>
              </a>
            </div>
            <div class="intro-logo-box">
              <a href="https://digital-assembly.hr/" target="_blank">
                <div class="logo-placeholder">
                  <img src="@/assets/digitalassembly.png" alt="digital assembly" />
                </div>
              </a>
            </div>
            <div class="intro-logo-box">
              <a href="https://paxos.com/" target="_blank">
                <div class="logo-placeholder">
                  <img src="@/assets/paxos-logo2.svg" alt="paxos" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="sidenav">
      <div class="sidenav-inner">
        <div class="sidenav-upper">
          <div class="close-sidenav" @click="closeNav()">
            <img src="@/assets/close-icon.png" alt="digital assembly" />
          </div>
        </div>
        <div class="sidenav-links">
          <p @click="pageSelect = 1, closeNav()">Home</p>
          <p @click="pageSelect = 2, closeNav()">About Us</p>
          <p @click="pageSelect = 3, closeNav()">Legal</p>
        </div>
      </div>
    </div>


    <div class="tickers-outer">
      <div class="tickers" v-if="tickers">
        <table>

          <tr>

            <th>#</th>

            <th>Name</th>

            <th class="right-moved">Price</th>

            <th class="right-moved">Change 24h</th>

          </tr>

          <tr v-for="(ticker, index) in tickers" :key="index">

            <td>{{ index + 1 }}</td>

            <td class="first-column">
              <div class="icon-holder">
                <img class="icon" :class="ticker.crypto_name == 'BCH' ? 'BCH_icon' : ''" :src="setCurrencySymbol(ticker.crypto_name)" alt="">
              </div>
              <p v-if="ticker.crypto_name == 'BTC'" class="crypto-name">Bitcoin</p>
              <p v-if="ticker.crypto_name == 'ETH'" class="crypto-name">Ethereum</p>
              <p v-if="ticker.crypto_name == 'LTC'" class="crypto-name">Litecoin</p>
              <p v-if="ticker.crypto_name == 'BCH'" class="crypto-name">Bitcoin Cash</p>
              <p v-if="ticker.crypto_name == 'PAXG'" class="crypto-name">Paxos Gold</p>
              <p class="abbrivation">{{ ticker.crypto_name }}</p>
            </td>

            <td class="right-moved"><p class="amount">{{ parseFloat(ticker.price).toFixed(2) }} $</p></td>

            <td class="right-moved">
              <p class="rise_amount" :class="setTickerColor(ticker.delta)">{{ setTickerPrefix(ticker.delta) + parseFloat(ticker.delta).toFixed(2) + " $ (" + setTickerPrefix(ticker.delta) + parseFloat(ticker.delta_decimal).toFixed(3) + "%)" }}</p>
            </td>

          </tr>
          
      </table>


       <!-- <div class="ticker" v-for="(ticker, index) in tickers" :key="index" >
          <div class="currency">
            <img class="icon" :src="setCurrencySymbol(ticker.crypto_name)" alt="">
            <span class="amount">{{ parseFloat(ticker.price).toFixed(2) }} $</span>
          </div>
          <div class="stats">
            <p class="abbrivation">{{ ticker.crypto_name }}</p>
            <p class="rise_amount">{{ parseFloat(ticker.delta).toFixed(2) }} $</p>
            <p class="rise_procentage">{{ parseFloat(ticker.delta_decimal).toFixed(3) }}%</p>
            <p v-if="ticker.crypto_name == 'BTC'">Bitcoin</p>
            <p v-if="ticker.crypto_name == 'ETH'">Ethereum</p>
            <p v-if="ticker.crypto_name == 'LTC'">Lite Coin</p>
            <p v-if="ticker.crypto_name == 'BCH'">Bitcoin Cash</p>
            <p v-if="ticker.crypto_name == 'PAXG'">Paxus Gold</p>
          </div>
        </div>-->


        
      </div>
    </div>



    <div class="presentation-box">
      <div class="presentation-box-inner">
        <div class="presentation-box-inner-half">
          <div class="placeholder-bigpic-1">
            <img src="@/assets/hex1.png" alt="digital assembly" />
          </div>
        </div>
        <div class="presentation-box-inner-half">
          <h4>Bridging Tradition with Tomorrow.</h4>
          <p>
            Adria Digital Exchange envisions a future where traditional financial institutions
            seamlessly integrate with the groundbreaking world of cryptocurrencies. We strive to be
            the catalyst for this convergence, offering a regulated and secure gateway to the vast potential of digital
            assets.
          </p>
        </div>
      </div>
    </div>

    <div class="presentation-box">
      <div class="presentation-box-inner invering">
        <div class="presentation-box-inner-half center-image">
          <div class="placeholder-bigpic-1">
            <img src="@/assets/hex2.png" alt="digital assembly" />
          </div>
        </div>
        <div class="presentation-box-inner-half">
          <h4>Enhance Financial Instruments with DLT.</h4>
          <p>
            Forward-looking in the regulated space, ADE will continue to strive and bring together
            traditional MiFID II financial instruments and distributed ledger technologies (DLT).
            On that journey, ADE will unlock the full potential of DLT not only in operational and
            security sense, but also try to enhance current regulatory requirements.
          </p>
        </div>
      </div>
    </div>



    <!--<div class="middle-box">
      <div class="middle-box-inner">
        <h3>Which institutional clients trust Adria Digital Exchange for crypto trading?</h3>
        <p>
          The Adria Digital Exchange offering is tailored to the specific needs of professional crypto asset traders: 
          Investment Banks and Asset Managers for Digital Assets, Crypto Funds, Brokers and Trading Desks, 
          Market Makers and Liquidity Providers. In addition, we also enable institutional investors to find 
          a way into this asset class. We are the sought-after partner who  provides you and your customers with 
          a trustworthy access to crypto assets that is integrated into your offering.
        </p>
      </div>
    </div>-->

    <div class="middle-box">
      <div class="middle-box-inner">
        <h3>Investing in Cryptocurrencies</h3>
        <p>
          Cryptocurrency trading involves inherent risks that may result in financial losses.
          Cryptocurrency prices can experience rapid and unpredictable changes. Factors such as market sentiment,
          regulatory developments, technological advancements, and macroeconomic trends contribute to this volatility.
          Investors should be aware of the psychological aspects of market dynamics and avoid making impulsive decisions
          based solely on emotions.
        </p>
      </div>
    </div>

    <div class="usluge-row">
      <div class="usluge-row-inner">
        <div class="usluge-box">
          <div class="usluge-box-inner">
            <div class="usluge-row-title">
              <div class="usluge-icon">
                <img src="@/assets/trade.png" alt="crypto trading" />
              </div>
              <h4>Institutional-Grade Trading Station</h4>
            </div>
            <p>
              Elevate your trading capabilities with our institutional-grade web application. Experience a powerful and
              intuitive platform designed to meet the rigorous demands of financial institutions. Access real-time data,
              advanced analytics, providing your institution with the tools needed to jump-start in world of crypto
              assets.
            </p>
          </div>
        </div>
        <div class="usluge-box">
          <div class="usluge-box-inner">
            <div class="usluge-row-title">
              <div class="usluge-icon">
                <img src="@/assets/code.png" alt="ADE API" />
              </div>
              <h4>Seamless Connectivity through API</h4>
            </div>
            <p>
              Embrace flexibility in your existing trading systems with our REST API connectivity. Integrate our platform
              seamlessly into your existing infrastructure, enabling your institution to automate trading processes, and
              retrieve market data efficiently – with FIX API to follow.
            </p>
          </div>
        </div>
        <div class="usluge-box">
          <div class="usluge-box-inner">
            <div class="usluge-row-title">
              <div class="usluge-icon">
                <img src="@/assets/cybersec.png" alt="cyber security" />
              </div>
              <h4>Cybersecurity and Reputation</h4>
            </div>
            <p>
              Security and regulatory compliance are paramount in the financial landscape. ADE is committed to maintaining
              the highest standards in cybersecurity. Moreover, our commitment to regulatory compliance establishes us as
              a trustworthy partner in the crypto space. Rest assured that your trading activities align with the latest
              industry regulations.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-inner">
        <div class="footer-half">
          <div class="footer-box">
            <div class="footer-logo">
              <img src="@/assets/logo-light.png" alt="ADE platform" />
            </div>
          </div>
          <div class="footer-box">
            <h5>Company Card</h5>
            <p><span>Short name:</span> Adria Digital Exchange LLC</p>
            <p><span>Competent court:</span> Commercial Court in Zagreb</p>
            <p><span>Registration number:</span> 081510700</p>
            <p><span>Identification number:</span> 16372761404</p>
            <p><span>IBAN:</span> HR4724120091139007126</p>
            <p><span>Board Members:</span> Tomislav Gračan, Saša Hunjak</p>
            <p>Regulated: <a
                href="https://www.hanfa.hr/podrucja-nadzora/virtualne-valute/registar-pruzatelja-usluga-virtualne-imovine/"
                target="_blank" class="hanfa">Croatian Financial services Supervisory Agency</a></p>
          </div>
        </div>
        <div class="footer-half">
          <div class="footer-box"></div>
          <div class="footer-box">
            <h5>Contact Us</h5>
            <p>Ulica grada Vukovara 271</p>
            <p>10 000, Zagreb</p>
            <p>Croatia</p>
            <a href="mailto: contact@ade.hr" class="email-link">E-Mail Us</a>

          </div>
        </div>
      </div>
      <div class="footer-copy">
        <p>© Copyright <span>Adria Digital Exchange</span>. All Rights Reserved</p>
        <p>2023.</p>
      </div>
    </div>
  </div>

  <div class="content" v-if="pageSelect === 2">
    <div class="intro-2">
      <div class="inner-intro-2">
        <div class="menu">
          <div @click="pageSelect = 1" class="menu-logo">
            <img src="@/assets/logo-dark.png" alt="ADE platform" />
          </div>
          <div class="menu-links">
            <p @click="pageSelect = 1, closeNav()">Home</p>
            <p @click="pageSelect = 2, closeNav()">About Us</p>
            <p @click="pageSelect = 3, closeNav()">Legal</p>
          </div>
          <div class="mobile-menu-close" @click="openNav()">
            <img src="@/assets/Hamburger_icon.svg.png" alt="ADE platform" />
          </div>
        </div>


      </div>
    </div>

    <div id="sidenav">
      <div class="sidenav-inner">
        <div class="sidenav-upper">
          <div class="close-sidenav" @click="closeNav()">
            <img src="@/assets/close-icon.png" alt="digital assembly" />
          </div>
        </div>
        <div class="sidenav-links">
          <p @click="pageSelect = 1, closeNav()">Home</p>
          <p @click="pageSelect = 2, closeNav()">About Us</p>
          <p @click="pageSelect = 3, closeNav()">Legal</p>
        </div>
      </div>
    </div>



    <div class="presentation-box">
      <div class="presentation-box-inner">
        <div class="presentation-box-adria">
          <h4 class="left-moved">Adria Digital Exchange</h4>
          <p>
            ADE ensures transparent and secure investment in virtual assets based on clearly defined criteria by
            developing regulated trading solutions customized for financial institutions and brokerages. Our in-depth
            understanding of the dynamic nature of financial markets positions us as a reliable partner for those seeking
            secure and compliant cryptocurrency trading solutions.
          </p>
          <p>
            At the forefront of the regulated space, ADE is not only a pioneer in current crypto offerings but also looks
            forward, aiming to bridge the gap between traditional MiFID II financial instruments and cutting-edge
            distributed ledger technologies (DLT). As we embark on this transformative journey, our commitment is to
            unlock the full potential of DLT, not just in terms of operational efficiency and security enhancements but
            also by actively seeking to elevate existing regulatory standards. We understand that the evolving landscape
            demands continuous adaptation, and we strive to be at the forefront of innovation within the regulatory
            framework. Our forward-looking approach positions ADE as a catalyst for positive change, as we explore ways to
            seamlessly integrate DLT into the traditional financial sector.
          </p>
          <p>
            As a testament to our commitment to excellence, ADE is proudly incorporated by both the Zagreb Stock Exchange
            and Digital Assembly, entities that bring diverse knowledge and skill sets to fortify our foundation. This
            strategic collaboration allows us to leverage a wealth of expertise, ensuring that our platform not only meets
            but exceeds the stringent standards set by regulators.
            ADE is dedicated to shaping the future of finance by embracing the convergence of traditional and emerging
            technologies, fostering a landscape where security, compliance, and innovation harmoniously coexist.
          </p>
        </div>
      </div>
    </div>

    <!--<div class="presentation-box">
      <div class="presentation-box-inner invering">
        <div class="presentation-box-inner-half to-right">
          <div class="placeholder-bigpic-zse">
            <img src="@/assets/zb-multi.png" alt="zagreb stock exchange" />
          </div>
        </div>
        <div class="presentation-box-inner-half">
          <h4>Zagreb Stock Exchange</h4>
          <p>
            Zagreb Stock Exchange, the financial heartbeat of Croatia, serves as a 
            pivotal player in the country's financial landscape, operating under the regulatory guidance of 
            the Croatian Financial Services Supervisory Agency (HANFA). At ZSE, investors engage in trading of a 
            diverse range of financial instruments, including stocks, bonds, and other securities.
          </p>
        </div>
      </div>
    </div>

    <div class="presentation-box">
      <div class="presentation-box-inner">
        <div class="presentation-box-inner-half">
          <div class="placeholder-bigpic-1">
            <img src="@/assets/digitalassembly.png" alt="digital assembly" />
          </div>
        </div>
        <div class="presentation-box-inner-half">
          <h4>Digital Assembly</h4>
          <p>
            Digital Assembly is a software and blockchain development company based in Croatia. 
            With our background rooted in fintech, Digital Assembly provides a full range of enterprise-level IT 
            and blockchain services for businesses, financial organizations, and investment funds. We offer a wide 
            variety of solutions based on state-of-the-art technology, tailor-made to achieve your goals while connecting 
            you to a team that’s ready to commit from day one.
          </p>
        </div>
      </div>
    </div>-->

    <!-- <div class="companies-section">
      <div class="companies-section-inner">
        <div class="company-box">
          <div class="placeholder-bigpic-2">
            <img src="@/assets/zb-multi.png" alt="zagreb stock exchange" />
          </div>
          <h4>Zagreb Stock Exchange</h4>
          <p>
            Zagreb Stock Exchange, the financial heartbeat of Croatia, serves as a 
            pivotal player in the country's financial landscape, operating under the regulatory guidance of 
            the Croatian Financial Services Supervisory Agency (HANFA). At ZSE, investors engage in trading of a 
            diverse range of financial instruments, including stocks, bonds, and other securities.
          </p>
        </div>
        <div class="company-box">
          <div class="placeholder-bigpic-3">
            <img src="@/assets/digitalassembly.png" alt="digital assembly" />
          </div>
          <h4>Digital Assembly</h4>
          <p>
            Digital Assembly is a software and blockchain development company based in Croatia. 
            With our background rooted in fintech, Digital Assembly provides a full range of enterprise-level IT 
            and blockchain services for businesses, financial organizations, and investment funds. We offer a wide 
            variety of solutions based on state-of-the-art technology, tailor-made to achieve your goals while connecting 
            you to a team that’s ready to commit from day one.
          </p>
        </div>
      </div>
    </div>-->

    <div class="double-company">
      <div class="double-company-inner">
        <div class="company-half">
          <div class="company-half-logo">
            <a href="https://zse.hr/" target="_blank">
              <div class="zse-slika">
                <img src="@/assets/zb-multi.png" alt="zagreb stock exchange" />
              </div>
            </a>
          </div>
          <h4>Zagreb Stock Exchange</h4>
          <p>
            Zagreb Stock Exchange, the financial heartbeat of Croatia, serves as a
            pivotal player in the country's financial landscape, operating under the regulatory guidance of
            the Croatian Financial Services Supervisory Agency (HANFA). At ZSE, investors engage in trading of a
            diverse range of financial instruments, including stocks, bonds, and other securities.
          </p>
        </div>
        <div class="company-half">
          <div class="company-half-logo">
            <a href="https://digital-assembly.hr/" target="_blank">
              <div class="digital-slika">
                <img src="@/assets/digitalassembly.png" alt="digital assembly" />
              </div>
            </a>
          </div>
          <h4>Digital Assembly</h4>
          <p>
            Digital Assembly is a software and blockchain development company based in Croatia.
            With our background rooted in fintech, Digital Assembly provides a full range of enterprise-level IT
            and blockchain services for businesses, financial organizations, and investment funds. We offer a wide
            variety of solutions based on state-of-the-art technology, tailor-made to achieve your goals while connecting
            you to a team that’s ready to commit from day one.
          </p>
        </div>
      </div>
    </div>

    <!--<div class="team">
      <div class="team-inner">
        <div class="team-member">
          <div class="team-pic">
            <img src="@/assets/tomislav.png" alt="tomislav gracan" />
          </div>
          <h4>Tomislav Gračan</h4>
          <h5>Member of Management Board</h5>
          <div class="about-team">
            <button @click="tomislav = 2">About Me</button>
          </div>
          <a href="https://www.linkedin.com/in/tomislav-gračan-b8a83a24/" target="_blank">
            <div class="linkedin-icon">
              <img src="@/assets/linkedin.png" alt="tomislav gracan" />
            </div>
          </a>
        </div>
        <div class="team-member">
          <div class="team-pic">
            <img src="@/assets/sasa.png" alt="sasa hunjak" />
          </div>
          <h4>Saša Hunjak</h4>
          <h5>Member of Management Board</h5>
          <div class="about-team">
            <button @click="sasa = 2">About Me</button>
          </div>
          <a href="https://www.linkedin.com/in/saša-hunjak-056547109/" target="_blank">
            <div class="linkedin-icon">
              <img src="@/assets/linkedin.png" alt="tomislav gracan" />
            </div>
          </a>
        </div>
      </div>

      <div class="about-cv" v-if="tomislav === 2" >
        <div class="about-cv-inner">
          <h4>Tomislav Gračan</h4>
          <p>
            Tomislav Gračan graduated from the Faculty of Law in Zagreb in 1997, and in 2012, he completed the 
            Executive MBA program at Cotrugli Business School. He began his professional career in 1998 as an intern at the 
            Municipal Court in Đurđevac, where, after passing the judicial exam, he continued to work as a judicial advisor. 
            At the end of 2001, he joined Zagrebačka banka d.d., where he worked as a legal specialist in the Bilogora, Podravina, 
            and Prigorje Branch and in the Legal Affairs Directorate. In early 2003, he became an advisor to the Management Board at Dioki d.d., 
            performing tasks related to the organizational and cost restructuring of the company.
          </p>
          <p>
            In September 2004, he moved to the position of advisor to the Management Board for legal 
            affairs at HVB Leasing Croatia d.o.o. Following the integration of HVB Leasing Croatia d.o.o. and Locat Leasing 
            Croatia d.o.o. into UniCredit Leasing Croatia d.o.o., he became the Director of Operational Affairs. In September 2008, 
            he continued his career at Hypo-Leasing Kroatien d.o.o., where, as an advisor to the Management Board for operational affairs, 
            he worked on the restructuring of the company.
          </p>
          <p>
            After approval by the Croatian Financial Services Supervisory Agency, he began serving as a 
            member of the Management Board of the Zagreb Stock Exchange d.d. in May 2010. He is a member of the 
            Supervisory Board of the Ljubljana Stock Exchange and the director of Adria Digital Exchange d.o.o.
          </p>
        </div>
  
        <div class="cv-close" @click="tomislav = 1">
          <div class="close-cv">
            <img src="@/assets/close-icon.png" alt="ADE platform" />
          </div>
          <p>Close</p>
        </div>
      </div>

      <div class="about-cv" v-if="sasa === 2">
        <div class="about-cv-inner">
          <h4>Saša Hunjak</h4>
          <p>
            Saša Hunjak is co-founder and CFO of Digital Assembly d.o.o. and Member of Management Board of Adria 
            Digital Exchange d.o.o. Saša Hunjak is a seasoned finance professional with a unique blend of experience from the banking 
            sector, having worked at Privredna Banka Zagreb d.d. and Erste&Steiermärkische Bank d.d. His expertise extends to the cutting-edge 
            field of information technology, particularly blockchain technology. He is a dedicated professional who is passionate about merging 
            finance and technology to create innovative solutions for the capital market while optimizing and enhancing existing financial products 
            and protocols. Saša has a master’s degree in mathematics and physics from University of Zagreb, Faculty of Science, and currently is 
            attending postgraduate studies in Financial and Company Law at University of Rijeka, Faculty of Law.
          </p>
        </div>

        <div class="cv-close" @click="sasa = 1">
          <div class="close-cv">
            <img src="@/assets/close-icon.png" alt="ADE platform" />
          </div>
          <p>Close</p>
        </div>
      </div>
      
      
    </div>-->

    <div class="middle-box">
      <div class="middle-box-inner">
        <h3>Fully Compliant</h3>
        <p>
          ADE is approved by Croatian Financial Services Supervisory Agency as a company registered to offer and operate:
        </p>

        <div class="mock-list">
          <p>(I) custody and management of virtual assets</p>
          <p>(II) management of a platform for trading virtual assets</p>
          <p>(III) execution of orders for virtual assets</p>
          <p>(IV) receipt and transfer of orders for virtual assets on behalf of third parties</p>
        </div>

        <a Href="https://www.hanfa.hr/podrucja-nadzora/virtualne-valute/registar-pruzatelja-usluga-virtualne-imovine/">
          https://www.hanfa.hr/podrucja-nadzora/virtualne-valute/registar-pruzatelja-usluga-virtualne-imovine/
        </a>
      </div>
    </div>

    <!--<div class="middle-box-2">
      <div class="middle-box-inner">
        <h3>Moving Forward</h3>
        <p>
          The Adria Digital Exchange offering is tailored to the specific needs of professional crypto asset traders: 
          Investment Banks and Asset Managers for Digital Assets, Crypto Funds, Brokers and Trading Desks, 
          Market Makers and Liquidity Providers. In addition, we also enable institutional investors to find 
          a way into this asset class. We are the sought-after partner who  provides you and your customers with 
          a trustworthy access to crypto assets that is integrated into your offering.
        </p>
      </div>
    </div>-->



    <div class="footer">
      <div class="footer-inner">
        <div class="footer-half">
          <div class="footer-box">
            <div class="footer-logo">
              <img src="@/assets/logo-light.png" alt="ADE platform" />
            </div>
          </div>
          <div class="footer-box">
            <h5>Company Card</h5>
            <p><span>Short name:</span> Adria Digital Exchange LLC</p>
            <p><span>Competent court:</span> Commercial Court in Zagreb</p>
            <p><span>Registration number:</span> 081510700</p>
            <p><span>Identification number:</span> 16372761404</p>
            <p><span>IBAN:</span> HR4724120091139007126</p>
            <p><span>Board Members:</span> Tomislav Gračan, Saša Hunjak</p>
            <p>Regulated: <a
                href="https://www.hanfa.hr/podrucja-nadzora/virtualne-valute/registar-pruzatelja-usluga-virtualne-imovine/"
                target="_blank" class="hanfa">Croatian Financial services Supervisory Agency</a></p>
          </div>
        </div>
        <div class="footer-half">
          <div class="footer-box"></div>
          <div class="footer-box">
            <h5>Contact Us</h5>
            <p>Ulica grada Vukovara 271</p>
            <p>10 000, Zagreb</p>
            <p>Croatia</p>
            <a href="mailto: contact@ade.hr" class="email-link">E-Mail Us</a>

          </div>
        </div>
      </div>
      <div class="footer-copy">
        <p>© Copyright <span>Adria Digital Exchange</span>. All Rights Reserved</p>
        <p>2023.</p>
      </div>
    </div>
  </div>

  <div class="content" v-if="pageSelect === 3">
    <div class="intro-2">
      <div class="inner-intro-2">
        <div class="menu">
          <div @click="pageSelect = 1" class="menu-logo">
            <img src="@/assets/logo-dark.png" alt="ADE platform" />
          </div>
          <div class="menu-links">
            <p @click="pageSelect = 1">Home</p>
            <p @click="pageSelect = 2">About Us</p>
            <p @click="pageSelect = 3">Legal</p>
          </div>
          <div class="mobile-menu-close" @click="openNav()">
            <img src="@/assets/Hamburger_icon.svg.png" alt="ADE platform" />
          </div>
        </div>


      </div>
    </div>

    <div id="sidenav">
      <div class="sidenav-inner">
        <div class="sidenav-upper">
          <div class="close-sidenav" @click="closeNav()">
            <img src="@/assets/close-icon.png" alt="digital assembly" />
          </div>
        </div>
        <div class="sidenav-links">
          <p @click="pageSelect = 1">Home</p>
          <p @click="pageSelect = 2">About Us</p>
          <p @click="pageSelect = 3">Legal</p>
        </div>
      </div>
    </div>

    <div class="links-section">
      <div class="links-section-inner">
        <h4>Useful Links.</h4>
        <p>Regulation on Market in Crypto Assets - MiCA ( EU 2023/1114)</p>
        <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32023R1114"
          target="_blank">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32023R1114</a>
        <p>Regulation on a pilot regime for market infrastructures based on distributed ledger technology - DLT</p>
        <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32022R0858"
          target="_blank">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32022R0858</a>
        <p>Croatian Financial Services Supervisory Agency</p>
        <a href="https://www.hanfa.hr/en/" target="_blank">https://www.hanfa.hr/en/</a>
        <p>Croatian National Bank</p>
        <a href="https://www.hnb.hr/" target="_blank">https://www.hnb.hr/</a>
        <p>European Securities and Markets Authority</p>
        <a href="https://www.esma.europa.eu/" target="_blank">https://www.esma.europa.eu/</a>
        <p>European Central Bank</p>
        <a href="https://www.ecb.europa.eu/home/html/index.en.html" target="_blank"
          class="break">https://www.ecb.europa.eu/home/html/index.en.html</a>
      </div>
    </div>

    <div class="podnaslov">
      <div class="podnaslov-inner">
        <h4>Documents.</h4>
      </div>
    </div>

    <div class="doc-box">
      <div class="doc-box-inner" @click="alidgeble = 2;">
        <p>Eligible Clients</p>
      </div>
      <div class="document-display" v-if="alidgeble === 2">
        <h4>REGULATION ON ACQUIRING USER ACCESS TO THE DIGITAL ASSET TRADING PLATFORM THROUGH THE COMPANY ADRIA DIGITAL EXCHANGE LTD</h4>
        <h5>GENERAL PROVISIONS</h5>
        <h5>Article 1.</h5>
        <p>
          (1) This Regulation governs the terms for acquiring and terminating user access to the digital asset trading platform, which will be provided to its users by the company Adria Digital Exchange d.o.o. through its proprietary trading system, the rights and obligations of users arising from acquiring such access, and the procedures associated with them.
        </p>
        <br />

        <h5>DEFINITIONS</h5>
        <h5>Article 2.</h5>
        <p>
          (1) In this Regulation, unless the content of individual provisions clearly implies otherwise, the following terms have the following meanings:
        </p>
        <ul>
          <li><p>ADE – Adria Digital Exchange d.o.o.;</p></li>
          <li><p>Price List – the price list of services prescribed by ADE;</p></li>
          <li><p>Digital Assets – crypto assets defined by Regulation (EU) 2023/1114 on crypto asset markets and virtual assets defined by the Law on the Prevention of Money Laundering and Terrorist Financing (NN 108/17, 39/19, 151/22);</p></li>
          <li><p>HANFA – Croatian Financial Services Supervisory Agency;</p></li>
          <li><p>General Terms – General Terms of Use issued by the company ADE;</p></li>
          <li><p>Regulation – this Regulation on acquiring membership/user access to the platform for trading crypto assets through the company ADE;</p></li>
          <li><p>Trading System – the trading system owned by ADE that allows members/users to connect to the platform for trading digital assets;</p></li>
          <li><p>User – a person who concludes a user access agreement with ADE;</p></li>
          <li><p>Regulation – Regulation (EU) 2023/1114 on crypto asset markets;</p></li>
          <li><p>ZTK – Capital Market Act (NN 65/18, 17/20, 83/21, 151/22).</p></li>
        </ul>

        <p>
          (2) Terms used in this Regulation that are in the singular form apply equally to the plural and vice versa unless the context requires otherwise.
        </p>
        <p>
          (3) Reference to a specific law or other regulation also implies any future amendments to that law or regulation.
        </p>
        <br />

        <h5>ACQUIRING USER ACCESS</h5>
        <h5>Article 3.</h5>
        <p>(1) The status of a user is acquired at the moment of concluding a user access agreement.</p>
        <p>(2) By acquiring the status of a user, the user irrevocably agrees to the application of this Regulation and other acts of ADE.</p>
        <p>(3) The status of a user cannot be acquired by succession.</p>
        <br />

        <h5>REQUIREMENTS FOR A USER STATUS</h5>
        <h5>Article 4.</h5>

        <p>
          (1) A user of the ADE trading system enabling connection to the platform for trading digital assets can be an investment company or credit institution from the Republic of Croatia, a member state of the EU, and third countries, under the conditions prescribed by MiCA Regulation, the Capital Market Act, and this Regulation.
        </p>

        <h5>Article 5.</h5>
        <p>
          (1) A user can also be a legal entity that:
        </p>
        <p>
          - has the legal form of a joint-stock company, limited liability company, or European company (societas europea);
        </p>
        <p>
          - has a share capital of at least EUR 50,000.00;
        </p>
        <p>
          - is registered to provide the service of receiving and transmitting orders for digital assets on behalf of third parties, and potentially one or more of the following services related to digital assets:
        </p>


        <ul>
          <li><p>custody and management of virtual assets;</p></li>
          <li><p>management of a platform for trading virtual assets;</p></li>
          <li><p>exchange of virtual assets for legal tender that is a legal means of payment;</p></li>
          <li><p>exchange of virtual assets for other virtual assets;</p></li>
          <li><p>execution of orders for virtual assets;</p></li>
          <li><p>services for the implementation of offers or sales of virtual assets;</p></li>
          <li><p>advice on virtual assets;</p></li>
          <li><p>management of a portfolio of digital assets;</p></li>
          <li><p>transfer of digital assets on behalf of clients.</p></li>
        </ul>

        <p>
          - has been in business for at least 3 (three) years continuously, if applicable;
        </p>
        <p>
          - has a good reputation; and
        </p>
        <p>
          - has an established risk management system proportionate to the type, scope, and complexity of the services and activities it performs and an appropriate system of internal controls.
        </p>
        <p>
          (2) The user can also be a legal entity from a third country that, according to the assessment of ADE’s Management Board, does not meet all the conditions from paragraph 1 of this Article or Articles 4 or 7 of these Regulation but is registered or has permission to provide services that are, by their nature, comparable to the services referred to in paragraph 1, point 3, of this Article, provided that such a User meets all the conditions required by regulations governing anti-money laundering and counter-terrorism financing in the European Union.
        </p>

        <h5>Article 6.</h5>
        <p>
          (1) A person who has submitted an application for admission to user status must meet all the conditions for acquisition of user status prescribed by this Regulation.
        </p>
        <p>
          (2) Any application for acquisition of user status from a person who does not meet the conditions prescribed by this Regulation will be rejected.
        </p>

        <h5>ORGANIZATIONAL, PERSONNEL, AND TECHNICAL REQUIREMENTS FOR USERS</h5>
        <h5>Article 7.</h5>

        <p>(1) A user must have:</p>
        <ul>
          <li><p>the appropriate approval from the competent authority of the home member state or third country to provide investment services and perform investment activities and ancillary services, or</p></li>
          <li><p>the appropriate approval or registration from the competent authority of the home member state or third country to provide services related to digital assets.</p></li>
        </ul>

        <h5>Article 8.</h5>
        <p>
          (1) A user must have at least one authorized person, who, based on employment or other legal basis, is qualified to work on the trading system managed by ADE, enabling access to the platform for trading crypto assets.
        </p>
        <p>
          (2) A user must appoint:
        </p>
        <p>
          - at least one person responsible for supervising the trading system of the user, especially for:
        </p>
        <ul>
          <li><p>administering identification marks and assigning usernames and passwords and access rights to the trading system for all persons using the system at the respective user,</p></li>
          <li><p>assigning certain levels of rights to use the trading system, and</p></li>
        </ul>
        <p>
          - a person authorized to make decisions in exceptional circumstances.
        </p>

        <p>
          (3) A member/user must promptly inform ADE of any changes to persons listed in paragraphs 1 and 2 of this article.
        </p>
        <p>
          (4) A member/user must continuously maintain an appropriate level of expertise for individuals listed in paragraphs 1 and 2 of this article, in accordance with this Regulation and other acts of ADE.
        </p>
        <p>
          (5) To acquire or maintain the knowledge and skills necessary for certain tasks on the trading system, ADE may prescribe a training and development obligation for individuals performing these tasks at the user.
        </p>

        <h5>Article 9.</h5>
        <p>
          (1) A user must prevent unauthorized persons from accessing the computer and communication equipment and the trading system of ADE.
        </p>
        <p>
          (2) Computer and communication equipment must be set up to prevent unauthorized access to the trading system.
        </p>
        <p>
          (3) The user is responsible for the costs of computer and communication equipment and any equipment installation at the member/user's location, including the costs of data connection to the trading system.
        </p>

        <h5>SECURITY STANDARDS</h5>
        <h5>Article 10.</h5>

        <p>
          (1) If a user takes or fails to prevent an action that endangers or could endanger the proper functioning of the trading system, including but not limited to security settings and functionalities of the trading system, ADE may deny the user access to the trading system.
        </p>
        <p>
          (2) The user is responsible for any damages incurred in the event specified in paragraph 1 of this article.
        </p>

        <h5>PROCEDURE FOR AQUISITION OF USER STATUS</h5>
        <h5>Article 11.</h5>

        <p>
          (1) A person intending to become a user of ADE must submit an application for acquisition of user access on the prescribed form, the content of which is determined by ADE (applicant).
        </p>

        <p>
          (2) The applicant must, if applicable, attach to the application for acquisition of user access:
        </p>

        <ul>
          <li><p>an excerpt from the commercial court or other official records where the applicant is registered as a legal entity, including the latest changes,</p></li>
          <li><p>the decision of the competent authority approving the provision of investment services and the performance of investment activities, or the decision of the competent authority approving the operation for providing services related to digital assets,</p></li>
          <li><p>a brief overview of past business and plans of action on the ADE trading system,</p></li>
          <li><p>two copies of the user access agreements signed by persons authorized to represent the person intending to become a user of ADE,</p></li>
          <li><p>proof of paid fees, in accordance with the Price List.</p></li>
        </ul>

        <p>
          (3) The applicant may be asked to submit additional documentation upon ADE's request, in addition to the documents listed in paragraph 2 of this article.
        </p>

        <p>
          (4) The person intending to become a user of ADE will enable ADE to verify the accuracy of the information provided in the application for acquisition of user access.
        </p>

        <h5>Article 12.</h5>
        <p>
          (1) The applicant for acquisition of user access from Article 5 of this Regulation must, in addition to the application, also attach:
        </p>
        <p>
          - financial statements for the last 3 (three) business years, if applicable;
        </p>
        <p>
          - evidence that the applicant and/or a member of the management board of the applicant has not been finally convicted of criminal offenses against values protected by international law and for none of the following criminal offenses:
        </p>
        <ul>
          <li><p>fraud,</p></li>
          <li><p>against property, where criminal proceedings are initiated ex officio,</p></li>
          <li><p>against the economy,</p></li>
          <li><p>against justice,</p></li>
          <li><p>forgery,</p></li>
          <li><p>against official duty,</p></li>
          <li><p>disclosure of secret data,</p></li>
          <li><p>money laundering,</p></li>
          <li><p>financing terrorism,</p></li>
        </ul>

        <p>
          - from the law regulating the establishment and operation of companies, or acts corresponding to these offenses in their description, and has not been finally convicted of a more serious offense that constitutes a violation of regulations from the financial business sector, a document providing an overview of the established risk management system proportionate to the type, scope, and complexity of the services and activities performed by the applicant, including a description of the system of internal controls.
        </p>

        <p>
          (2) The applicant may be asked to submit additional documentation upon ADE's request, in addition to the documents listed in paragraph 1 of this article.
        </p>
        <br />

        <h5>8. EVALUATION OF APPLICATION FOR USER ACCESS</h5>
        <h5>Article 13.</h5>

        <p>
          (1) ADE shall make an assessment of the application for acquisition of user access no later than within 10 (ten) working days from the date of receiving a complete application and the prescribed documentation.
        </p>
        <p>
          (2) If ADE deems the application incomplete, it will invite the applicant to supplement the application within a period determined by ADE, which cannot be shorter than 5 (five) working days.
        </p>
        <p>
          (3) After ADE deems the application complete, it will proceed to conclude a user access agreement with the applicant.
        </p>
        <p>
          (4) Notice of rejection of the application for acquisition of user access does not have to be justified.
        </p>

        <h5>Article 14.</h5>
        <p>
          (1) Within a period not exceeding 3 (three) months from the date of concluding a user access agreement, the user is obliged to ensure access to the ADE trading system.
        </p>
        <p>
          (2) ADE may require the user to provide an appropriate security instrument.
        </p>
        <br />

        <h5>9. RIGHTS AND OBLIGATIONS OF USERS</h5>
        <h5>Article 15.</h5>

        <p>
          A user has the following rights:
        </p>

        <ul>
          <li><p>access to the trading system managed by ADE,</p></li>
          <li><p>use the trading system and enter into transactions through the system on their behalf and for their own account or for the account of a client,</p></li>
          <li><p>access to information related to trading,</p></li>
          <li><p>access to information on the listing, temporary suspension, and cessation of listing of digital assets available through the ADE trading system,</p></li>
          <li><p>access to information on the acquisition, suspension, and termination of user access to the ADE trading system, and</p></li>
          <li><p>other rights prescribed by this Regulation and other acts of ADE.</p></li>
        </ul>

        <h5>Article 16.</h5>
        <p>
          A user has the following obligations:
        </p>
        <ul>
          <li><p>comply with this Regulation and other acts of ADE when trading through the ADE system,</p></li>
          <li><p>ensure that the conditions for acquisition of user access prescribed by this Regulation and other acts of ADE are fulfilled for the entire duration of their user status,</p></li>
          <li><p>provide ADE with data in accordance with this Regulation and other acts of ADE,</p></li>
          <li><p>protect their trading system, identification marks, and user names and passwords for access to the ADE trading system,</p></li>
          <li><p>at the request of ADE, allow monitoring of their trading system and business,</p></li>
          <li><p>at the request of ADE, provide data or allow access to records of orders and trading decisions and records of transactions,</p></li>
          <li><p>fulfill financial and other obligations in accordance with contracts concluded with ADE or prescribed by this Regulation and other acts of ADE.</p></li>
        </ul>
        <br />
        <h5>Article 17.</h5>
        <p>
          (1) All users have the right to access the trading system and the trading platform through ADE.
        </p>
        <p>
          (2) Technical terms for accessing the trading system or the platform are determined by ADE and/or the platform connection service provider.
        </p>
        <p>
          (3) The user is obliged to keep confidential the process of connecting to the trading system or platform, technical documentation about the trading system and platform, computer program interface, and other documentation that ADE or the platform connection service provider determines to be a business secret.
        </p>
        <p>
          (4) The user must take all necessary measures to prevent unauthorized access and use of the trading system or platform, directly or indirectly, by unauthorized persons.
        </p>

        <h5>Article 18.</h5>
        <p>
          (1) The user is obliged to:
        </p>

        <ul>
          <li><p>conduct a prior assessment of the appropriateness and suitability of their clients and take all actions in accordance with the Anti-Money Laundering and Terrorism Financing Act,</p></li>
          <li><p>ensure that their clients are familiar with the content of regulations governing the trading of digital assets, this Regulation, and other acts of ADE regulating trading,</p></li>
          <li><p>establish appropriate systems and measures, including pre- and post-trade controls, to prevent incorrect entry of orders or behavior that may indicate market abuse,</p></li>
          <li><p>ensure the right to disable trading for the client at any time, and</p></li>
          <li><p>take other measures and procedures to ensure that client orders fully comply with the provisions of regulations governing the trading of digital assets, this Regulation, or other acts of ADE.</p></li>
        </ul>

        <p>
          (2) The member/user is responsible for all client orders entered into the trading system, completed transactions, and other actions taken on the trading system under their identification mark.
        </p>

        <p>
          (3) ADE has the right to disable the user from trading at any time if it deems that it is not in accordance with the provisions of this Regulation or other acts of ADE or if it believes that it constitutes behavior that may indicate market abuse.
        </p>

        <h5>Article 19.</h5>
        <p>
          (1) A user has the right to enter orders into the trading system for their own account or for the account of third parties and conclude transactions.
        </p>
        <p>
          (2) All entered orders and executed transactions containing the identification mark of the user are legally binding for that member/user.
        </p>
        <p>
          (3) Trading is anonymous, meaning users do not see the other party in the transaction (counterparty).
        </p>
        <br />

        <h5>Article 20.</h5>
        <p>
          (1) ADE assigns an identification mark to each user for access to the trading system.
        </p>
        <p>
          (2) The identification mark may be used exclusively by the user to whom it is assigned.
        </p>
        <p>
          (3) The user is responsible for all actions occurring on the trading system under their identification mark.
        </p>
        <br />

        <h5>Article 21.</h5>
        <p>
          (1) In case of abuse of the user's identification elements (identification marks, usernames, and passwords), the user must immediately inform ADE verbally and later in writing.
        </p>
        <p>
          (2) ADE will deactivate the identification elements immediately upon receipt of the user's notice of abuse and make changes to them. Deactivating identification elements does not affect the validity of transactions concluded until their deactivation.
        </p>
        <p>
          (3) ADE may, at the request of the user, temporarily or permanently suspend a suspicious user, cancel all open orders of the suspicious user or member, provide the user with a report on the concluded transactions of the suspicious user, or disable the user's access to the trading system or take other measures and activities appropriate to the circumstances of each case.
        </p>
        <br />

        <h5>Article 22.</h5>
        <p>
          (1) ADE ensures that the trading system has sufficient capacity to perform functions without malfunctions or interruptions.
        </p>
        <p>
          (2) For the purpose stated in paragraph 1 of this article, ADE determines the maximum allowed number of messages per second that a user can enter into the trading system.
        </p>
        <br />

        <h5>Article 23.</h5>
        <p>
          (1) Before the introduction or significant upgrade, a user must conduct compliance testing of their access to the ADE trading system, their trading system, trading algorithms, or trading strategies.
        </p>
        <p>
          (2) Compliance testing ensures that the basic functioning of the user's trading system, algorithms, and strategies is correct and in line with ADE's requirements.
        </p>
        <br />

        <h5>Article 24.</h5>
        <p>
          (1) Before introducing changes or upgrades to the trading system, ADE conducts testing of it.
        </p>
        <p>
          (2) ADE is authorized to require the user to participate in testing the trading system, in which case the user is obliged to conduct testing in the manner and according to the schedule determined by ADE.
        </p>
        <p>
          (3) ADE will, except in exceptional circumstances, timely inform users of testing of the trading system that could affect the daily business of the user.
        </p>
        <br />

        <h5>Article 25.</h5>
        <p>
          (1) ADE reserves the right to make changes to the trading system, such as changes to computer and communication equipment, changes to computer programs, changes to data connection protocols with the trading system, etc.
        </p>
        <p>
          (2) ADE will, except in exceptional circumstances, timely inform members/users of changes to the trading system.
        </p>
        <p>
          (3) In the case of major changes that may require a change in the user's computer programs and/or computer and communication equipment, the ADE notice will include a description of the changes, the planned testing time, and the date the changes to the trading system take effect.
        </p>
        <p>
          (4) Changes to the trading system will be implemented in the manner and within the deadlines determined by ADE.
        </p>
        <p>
          (5) The user is obliged to follow ADE's instructions regarding changes to the trading system and, in accordance with them, modify their computer programs or computer and communication equipment.
        </p>
        <p>
          (6) Changes to computer programs and computer and communication equipment that may affect the orderly functioning of the trading system must be reported to ADE without delay.
        </p>
        <p>
          (7) ADE may require testing of modified computer programs and computer and communication equipment of the user, under the conditions and in the manner prescribed by technical documentation.
        </p>
        <br />

        <h5>Article 26.</h5>
        <p>
          (1) ADE may disable access to or use of the trading system or restrict the functions of the trading system for preventive maintenance or necessary repairs to the trading system or for an upgrade of the trading system.
        </p>
        <p>
          (2) Actions from paragraph 1 of this article will generally be planned at times when there historically is less trading activity on the trading system.
        </p>
        <p>
          (3) ADE will, except in exceptional circumstances, timely inform users of its services about the implementation of these actions.
        </p>
        <br />

        <h5>Article 27.</h5>
        <p>
          (1) A user is obliged to immediately inform ADE of changes to all data based on which they acquired user access to the ADE trading system, especially:
        </p>

        <ul>
          <li><p>the expiration of the license for providing investment services and performing investment activities,</p></li>
          <li><p>the expiration of registration for providing services related to digital assets,</p></li>
          <li><p>all changes to persons conducting client order activities or persons authorized to supervise the member/user's trading system,</p></li>
          <li><p>all changes to the members of the management board or executive directors if the user has a structured management board,</p></li>
          <li><p>if financial or other circumstances arise for the member/user that can reasonably be expected to affect the fulfillment of the member/user's obligations or that could affect the functioning of ADE, other users, investors, and the orderly functioning of the trading system (e.g., illiquidity, inability to pay, or overindebtedness of the user, imposition of early intervention measures by Hanfa or another competent authority, initiation of bankruptcy proceedings or liquidation proceedings, significant court or arbitration proceedings, etc.),</p></li>
          <li><p>if the user undergoes changes in status (e.g., merger, acquisition, division) or other forms of corporate restructuring and changes to the subject of business/activity, and</p></li>
          <li><p>changes in qualified ownership stakes in the company.</p></li>
        </ul>
        <br />
        <p>
          (2) At the request of ADE, a user is obliged to provide ADE with other data and documents that affect or could affect the fulfillment of the user's obligations, the functioning of ADE, other users of ADE, investors, and the orderly functioning of the trading system.
        </p>
        <p>
          (3) ADE undertakes to keep confidential all data received from the user and will not provide such data to third parties or disseminate the data without the prior consent of the user, unless otherwise provided by applicable regulations, this Regulation, or other acts of ADE.
        </p>
        <br />

        <h5>Article 28.</h5>
        <p>
          (1) A user is obliged to keep records of orders and transaction records.
        </p>
        <p>
          (2) Upon request from ADE, a user is obliged to provide ADE with data from the records from paragraph 1 of this article, in the form, manner, and within the deadline specified by ADE in its request.
        </p>
        <br />

        <h5>Article 29.</h5>
        <p>
          (1) A user is obliged to inform persons using the trading system and conducting trading-related activities of the content of legal and other regulations, this Regulation, and other acts of ADE regulating trading on the digital asset market.
        </p>
        <p>
          (2) A user is obliged to ensure that their employees adhere to the rules and standards of the profession.
        </p>
        <br />

        <h5>Article 30.</h5>
        <p>
          (1) A user must not provide advice, recommendations, issue or distribute written materials, or use the media in a way that causes or could cause harm to the interests or reputation of ADE.
        </p>
        <p>
          (2) A user has the right to indicate on business paper, their website, or by other suitable means that they are a user of the ADE trading system and may use the ADE sign, taking care not to mislead third parties regarding the separation of the user's legal entity and ADE.
        </p>
        <p>
          (3) ADE may prescribe in detail the manner and use of the ADE sign and company name by users of ADE services.
        </p>
        <br />

        <h5>Article 31.</h5>
        <p>
          (1) A user gains access to ADE information in accordance with provisions governing access to the trading system.
        </p>
        <p>
          (2) ADE information includes information related to trading, listing, temporary suspension, and delisting of digital assets on the ADE trading system, as well as other information related to ADE activities.
        </p>
        <p>
          (3) A user is authorized to use information from paragraph 2 of this article solely for their own needs.
        </p>
        <p>
          (4) ADE determines the type of information that a member/user can distribute without restrictions and publishes it on its website. In other cases, a user may distribute ADE information only if they have entered into an information distribution agreement with ADE, or a person authorized by ADE.
        </p>
        <br />

        <h5>Termination of User Access</h5>
        <h5>Article 32.</h5>

        <p>
          (1) User access may cease:
        </p>

        <ul>
          <li><p>at the user's request or</p></li>
          <li><p>by exclusion from termination of user access.</p></li>
        </ul>
        <br />
        <p>
          (2) Termination of the user status means the cessation of using all ADE services by the user.
        </p>
        <p>
          (3) With the cessation of user access, the rights and obligations of the user also cease.
        </p>
        <p>
          (4) Termination of user access in the case of paragraph 1, point 1 of this article occurs on the day of termination of the user access agreement, and in the case of paragraph 1, point 2 of this article on the day determined in the ADE decision on termination of user access, with the issuance of which the user access agreement ceases to be valid.
        </p>
        <p>
          (5) Exceptionally from paragraphs 3 and 4 of this article, monetary and other obligations of the user existing at the time of termination of user access, but not fulfilled, must be fulfilled by the user in accordance with this Regulation, the Price List, and other ADE acts or contracts concluded between ADE and the user.
        </p>
        <br />

        <h5>Article 33.</h5>
        <p>
          (1) User access may be terminated at the request of the user.
        </p>
        <p>
          (2) A user submits a request for termination of user access on the prescribed form, the content of which is determined by ADE, inviting termination of the user access agreement.
        </p>
        <p>
          (3) Upon receipt of the request from the previous paragraph, ADE and the user will promptly proceed with the termination of the user access agreement, in the manner and under the conditions prescribed by the same.
        </p>
        <br />

        <h5>Article 34.</h5>
        <p>
          (1) ADE may decide to terminate user access by revoking user access or deciding on suspension, for one or more of the following reasons:
        </p>
        <ul>
          <li><p>if the user fails to meet the conditions specified in Article 14 of this Regulation within 3 (three) months of concluding the membership/user access agreement,</p></li>
          <li><p>if the user ceases to meet the conditions for user access (e.g., expiration of the license to provide investment services and perform investment activities, etc.),</p></li>
          <li><p>by the cessation of the existence of the legal entity of the user,</p></li>
          <li><p>if the user fails to fulfill obligations arising from trading or its obligations under contracts concluded with ADE,</p></li>
          <li><p>if the user does not provide ADE with information, documentation, other documents, or records that they are required to keep in accordance with regulations governing digital asset business, this Regulation, or other ADE acts, or if the user does not enable ADE to conduct supervision,</p></li>
          <li><p>in the case of serious or repeated violations of the provisions of this Regulation or other ADE acts,</p></li>
          <li><p>if financial, status, or other circumstances arise for the user that can reasonably be expected to affect the fulfillment of the user's obligations or that could affect the functioning of ADE, other users, investors, and the orderly functioning of the trading system (e.g., illiquidity, inability to pay, or overindebtedness of the user, issuance of a pre-bankruptcy resolution, bankruptcy, or initiation of liquidation proceedings, etc.),</p></li>
          <li><p>if necessary to ensure fair, orderly, and transparent trading or protect the capacity of the trading system,</p></li>
          <li><p>in other cases prescribed by law or other applicable regulations.</p></li>
        </ul>
        <br />
        <p>
          (2) When making a decision on termination of user access by revoking of user access or suspension, ADE will take into account all facts and circumstances, especially the reason, severity, and impact on the business of ADE, other users, investors, and the orderly functioning of the trading system.
        </p>
        <p>
          (3) The decision on termination of user access or the decision on suspension must be justified.
        </p>
        <br />

        <h5>Article 35.</h5>
        <p>
          (1) Before deciding on the termination of user access by revoking of user access, ADE will allow the user to express their views on the facts and circumstances that constitute the basis for exclusion from revoking user access, except when the true state of affairs can be determined based on data available to ADE or when the user's expression of views is not possible, or when there are other justified reasons.
        </p>
        <p>
          (2) In the case of termination of user access, the user cannot reapply for status of the user before the expiration of 1 (one) year from the date of exclusion from termination of user access.
        </p>
        <br />

        <h5>Article 36.</h5>
        <p>
          (1) The decision on the suspension of all or certain ADE services is of a temporary nature.
        </p>
        <p>
          (2) Suspension can be imposed for a predetermined duration, or the end of suspension may be conditional on the cessation of circumstances that led to the suspension, but not longer than 12 (twelve) months.
        </p>
        <p>
          (3) During the suspension, the user is obliged to pay ADE user fees and other fees in accordance with the Price List and fulfill all other obligations to ADE.
        </p>
        <p>
          (4) Exceptionally from paragraph 3 of this article, from the day of the decision on the opening of bankruptcy proceedings against the user, the user's obligation to pay user fees and other fees in accordance with the Price List ceases.
        </p>
        <p>
          (5) During the suspension, the user has no right to state in business correspondence or otherwise that they are a user of ADE or use the ADE logo.
        </p>
        <p>
          (6) If the circumstances that led to the suspension do not cease to exist at the end of the 12 (twelve) months from the date of the ADE decision on suspension, the status of the user ceases by termination of user access.
        </p>
        <p>
          (7) In the case of paragraph 6 of this article, ADE will proceed as prescribed in Article 35 of this Regulation.
        </p>
        <br />
        <br />
        <br />





        <div class="close-button" @click="alidgeble = 1;">
          <div class="close-icon">
            <img src="@/assets/close-icon.png" alt="digital assembly" />
          </div>
          <p>Close</p>
        </div>
      </div>
    </div>

    <div class="doc-box">
      <div class="doc-box-inner" @click="terms = 2;">
        <p>Terms & Conditions</p>
      </div>
      <div class="document-display" v-if="terms === 2">
        <h4>GENERAL TERMS OF USE</h4>
        <h5>1. INTRODUCTORY PROVISIONS</h5>
        <p>
          1.1. The company Adria Digital Exchange Ltd, with its registered office in Zagreb, Ulica grada Vukovara 271, registered with the Commercial Court in Zagreb under registration number (MBS): 081510700, and tax number (OIB): 16372761404 (hereinafter referred to as the "Company"), is registered for information society services. Through its website and platform located at www.ade.hr (hereinafter referred to as the "Platform"), the Company offers its users services for receiving and transferring orders for virtual assets and other designated services.
        </p>
        <p>
          1.2. The Company directs the User, prior to ordering any product or service provided by the Company on the Platform, to familiarize themselves with these General Terms of Use (hereinafter referred to as the "Terms"). In case of any questions about the Terms and other rules on the Platform, the User is directed to contact the Company at contact@ade.hr.
        </p>
        <br />

        <h5>2. ACCEPTANCE OF THESE TERMS AND CONTRACT FORMATION</h5>
        <p>
          2.1. By accessing or using the Platform, the User accepts these Terms and all other rules published on the Company's website, especially the Privacy Policy. These Terms govern the basic rights, obligations, and responsibilities regarding the use of the Platform and the services offered on the Platform between the Company, on the one hand, and the user of the Platform (hereinafter referred to as the "User"), on the other hand.
        </p>
        <p>
          2.2. The User can only be a trader, specifically an investment firm, credit institution, or another legal entity, under the assumptions prescribed by the Regulation on the Acquisition of User Access to the Digital Asset Trading Platform through the Company Adria Digital Exchange. Individuals considered consumers cannot be Users. For these reasons, these Terms, as well as the entire contractual relationship with the User, are not subject to consumer protection rights.
        </p>
        <p>
          2.3. The provisions of these Terms are applied to visitors to the website where the Platform is located who do not have the status of Users.
        </p>
        <p>
          2.4. Further rights and obligations between the Company and the User are regulated by individual contracts, the conclusion of which is a prerequisite for personalized access and use of the Platform (hereinafter referred to as the "Special Agreement").
        </p>
        <br />

        <h5>3. OPENING A USER PROFILE, PROVIDING SERVICES, AND FEES</h5>
        <p>
          3.1. Opening a User Profile, Services provided by the Company through the Platform, and Fees for the Services are regulated by a Special Agreement.
        </p>
        <p>
          3.2. All matters concerning the relationship between the Company and the User that are not regulated by these Terms or a Special Agreement with the User are governed by the Regulation on the Acquisition of User Access to the Digital Asset Trading Platform through the Adria Digital Exchange.
        </p>
        <br />
        
        <h5>4. UNAUTHORIZED USE OF THE USER PROFILE</h5>
        <p>
          4.1. Only the User may use their identity and access credentials for using the Platform. The User must not disclose the details of their user profile to any other person. The User is responsible for all actions taken and omissions made using their user data.
        </p>
        <p>
          4.2. The User is responsible for maintaining the appropriate security and control of all logins, passwords, or any other data used to access or use the Platform. Any loss or compromise of this data may lead to unauthorized access by third parties and the loss of assets located in the user's account, for which the Company is not responsible.
        </p>
        <p>
          4.3. The Company is not liable for any damage to the User due to the compromise of personal data or non-compliance with these Terms and any notices or warnings sent by the Company to the User. If the User believes or suspects unauthorized access, they must immediately inform the Company at contact@ade.hr.
        </p>
        <br />

        <h5>5. RISKS ASSOCIATED WITH VIRTUAL ASSETS</h5>
        <p>
          5.1. By accepting these Terms, the User confirms awareness that digital assets are extremely volatile, their value depends on supply and demand, and they can change, decrease, or lose their value, either partially or entirely, in a short period. The Company is not responsible for the mentioned volatility and the loss of the User's asset value.
        </p>
        <br />

        <h5>6. USER'S OBLIGATIONS AND RESPONSIBILITIES</h5>
        <p>
          6.1. The User undertakes to comply with the provisions of these Terms, the Special Agreement, and other Platform rules and to use the Platform in accordance with applicable regulations.
        </p>
        <p>
          6.2. The User undertakes not to: (i) copy, transmit, sell, modify, publish, create derivative works or otherwise use the Platform, in whole or in part, for commercial or non-commercial purposes, except as expressly stated in these Terms; (ii) link the display of the Platform, in whole or in part, to any other website or medium without prior written permission from the Company; (iii) in any way disable, overload, damage, or disrupt the use of the Platform or any part of the Platform; (iv) introduce any malicious software into the Platform; (v) unauthorized access, disrupt, damage any part of the Platform, or any server, computer, or database connected to the Company's system; (vi) violate the intellectual property rights of the Company or any third party.
        </p>
        <p>
          6.3. The User is solely and exclusively responsible for their data, as described in Article 4 of these Terms and the Privacy Policy.
        </p>
        <br />

        <h5>7. DAMAGE COMPENSATION</h5>
        <p>
          7.1. The User undertakes to compensate the Company for any damage and costs arising from or related to any breach of these Terms or a Special Agreement.
        </p>

        <p>
          7.2. The Company is not liable to the User for its actions resulting from the obligation to comply with applicable laws, regulations, legal procedures, and requirements of competent authorities.
        </p>
        <br />

        <h5>8. USE OF THE PLATFORM</h5>
        <p>
          8.1. The Company grants the User a limited, non-exclusive, non-transferable license, in accordance with these Terms, solely for accessing and using the Platform and websites, its content, materials, information, and functionalities associated with the Platform. Any other use of the Platform by the User is expressly prohibited. All rights to the Platform are exclusive rights of the Company.
        </p>
        <p>
          8.2. The Company is not responsible for any damage or interruptions caused by circumstances beyond its control.
        </p>
        <br />

        <h5>9. INTELLECTUAL PROPERTY RIGHTS</h5>
        <p>
          9.1. The Platform, websites, and all content, features, and functionalities, including but not limited to software, text, display, images, video and audio recordings, and the design, selection, and arrangement, and all copyrights, trademarks, and other intellectual property rights therein, are the property of the Company, and the User does not acquire any rights to such content.
        </p>
        <p>
          9.2. The name and logo of the Company and the Platform, as well as all related names, logos, product and service names, designs, and slogans, are trademarks of the Company or third parties. The User may not use these marks without prior written permission from the Company or third parties.
        </p>
        <br />

        <h5>10. FINAL PROVISIONS</h5>
        <p>
          10.1. These Terms may be unilaterally amended by the Company at any time, and the Company shall not be responsible for any consequences of such amendments.
        </p>
        <p>
          10.2. Croatian law applies to these Terms.
        </p>
        <p>
          10.3. If a competent authority determines that any provision of these Terms is invalid or unenforceable, the invalidity or unenforceability of such provision shall not affect other provisions of these Terms. All provisions not affected by this remain in full legal force and effect, and the invalid or unenforceable provision shall be replaced with a valid and enforceable one.
        </p>
        <br />
        <br />
        <br />


        <div class="close-button" @click="terms = 1;">
          <div class="close-icon">
            <img src="@/assets/close-icon.png" alt="digital assembly" />
          </div>
          <p>Close</p>
        </div>
      </div>
    </div>

    <div class="doc-box">
      <div class="doc-box-inner" @click="privacy = 2;">
        <p>Privacy Policy</p>
      </div>
      <div class="document-display" v-if="privacy === 2">
        <h4>PRIVACY POLICY</h4>
        <h5>1. INTRODUCTION</h5>
        <p>
          We respect your privacy and your control over your personal data, 
          and we are dedicated to protecting the privacy of visitors to our website. 
          In this Privacy Policy ("Policy"), we explain how we will handle your personal data. 
          Upon your first visit to our website, we will request your consent to the use of cookies 
          in accordance with the terms of this Policy. We strictly adhere to best practices in the industry 
          and comply with the rules established by the General Data Protection Regulation EU No. 
          679/2016 ("GDPR"), OPPA Law, CAN-SPAM, and COPPA.
        </p>
        <br />

        <h5>2. HOW WE USE YOUR PERSONAL DATA</h5>
        <p>
          In this section 2, we outline:
        </p>
        <ul>
          <li><p>General categories of personal data that we may process,</p></li>
          <li><p>In the case of personal data not directly obtained from you, the source and specific categories of such data,</p></li>
          <li><p>Purposes of processing personal data,</p></li>
          <li><p>Legal bases for processing.</p></li>
        </ul>
        <p>
          Processing your personal data is necessary for the performance of a contractual relationship between us and you.
        </p>
        <p>
          We may process data about your use of our website ("usage data"). Usage data may include your IP address, 
          geographical location, type and version of browser, operating system, duration of visit, page views, and 
          navigation paths on the website, as well as information about the timing, frequency, and pattern of use of the 
          website. The source of usage data is our analytics tracking system. This usage data may be processed for the purpose 
          of analyzing the use of the website. The legal basis for this processing is our legitimate interests, i.e., monitoring 
          and improving our website.
        </p>
        <p>
          We may process information that you post for public disclosure on our website ("publication data"). 
          Publication data may be processed for the purpose of enabling such publication and managing our website. 
          The legal basis for this processing is our legitimate interests, i.e., proper management of our website and business.
        </p>

        <p>
          We may process any of your personal data listed in other provisions of this Policy when 
          necessary to establish, exercise, or defend legal claims, either in court proceedings or in 
          administrative or out-of-court proceedings. The legal basis for this processing is our legitimate 
          interests, i.e., the protection and enforcement of our rights, your rights, and the rights of others.
        </p>

        <p>
          We may also process your personal data for specific purposes based on your consent if we have 
          specifically requested and obtained your consent. In addition to the specific purposes of processing 
          your personal data listed in this section 2, we may also process any of your personal
          data when such processing is necessary to fulfill a legal obligation to which we are subject or to protect 
          your vital interests or the vital interests of another natural person.
          Please do not provide us with the personal data of other individuals unless prompted to do 
          so. We do not collect any information that could identify you without your permission. However, on our website, 
          we use Google Analytics. If you want to learn more about Google Analytics and its "Do Not Track" policy, visit <a href="https://www.google.com/analytics/terms/us.html" target="_blank">https://www.google.com/analytics/terms/us.html.</a>
        </p>
        <br />

        <h5>3. PROVIDING YOUR PERSONAL DATA TO OTHERS</h5>
        <p>
          We may disclose your personal data to any member of our group of companies (which includes our subsidiaries, our ultimate holding company, and all its subsidiaries) as reasonably necessary for the purposes set out in this Policy.
        </p>
        <p>
          We may disclose your personal data to our insurers and/or professional advisers as reasonably necessary for the purposes of obtaining and maintaining insurance coverage, managing risks, obtaining professional advice, and managing legal disputes or to a buyer (or prospective buyer) of any business or asset that we are selling (or contemplating selling). We may disclose your inquiry data to one or more selected suppliers of goods and services third parties identified on our website for the purpose of enabling them to contact you so that they can offer, market, and sell to you relevant products and/or services. Each such third party will act as a data controller in relation to the inquiry data that we supply to it; and each such third party will, upon contacting you, provide you with a copy of its own privacy policy that will govern the use of your personal data by that third party.
        </p>

        <p>
          In addition to the specific disclosures of personal data set out in this Section 3, we may also disclose your personal data when such disclosure is necessary for the fulfillment of a legal obligation to which we are subject, or to protect your vital interests or the vital interests of another natural person. We do not sell, trade, or rent your personal data to others. We may share generic aggregated demographic information that is not linked to any personal data about visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
        </p>
        <br />

        <h5>4. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</h5>
        <p>
          Hosting facilities for our website are located within the European Union. Any potential transfers to any other country will be protected by appropriate safeguards, i.e., compliance with the GDPR. You agree that personal data you submit for publication through our website may be available worldwide via the internet. We cannot prevent the use (or misuse) of such personal data by others.
        </p>

        <h5>5. RETENTION AND DELETION OF PERSONAL DATA</h5>
        <p>
          This Section 5 outlines our data retention policy and procedure, designed to help us comply with our legal obligations regarding the retention and deletion of personal data.
We will retain publication data and inquiry data only for as long as they are relevant for the purpose for which they were collected, and we will delete all personal data that is no longer relevant. We will also adhere to all applicable laws or regulations that require us to retain your personal data for a specified period, such as mandatory retention periods under accounting laws and regulations. At the end of the retention period, personal data will be anonymized and deleted from our systems. This means deleting all personal information that can be used to identify an individual, replacing it with an anonymous record.
        </p>

        <p>
          Regarding any personal data we process based on your consent, we will retain such data as long as they are relevant for the purpose for which the data was collected unless you withdraw your consent before that.
        </p>

        <p>
          Continuing with other provisions of this Section 5, we may retain your personal data if such retention is necessary to fulfill a legal obligation to which we are subject or to protect your vital interests or the vital interests of another natural person.
        </p>
        <br />

        <h5>6. YOUR RIGHTS</h5>
        <p>
          In this Section 6, we have summarized the rights you have under applicable data protection laws. Some of the rights are complex, and not all details are included in our summaries. Therefore, you should read the relevant laws and guidance from the relevant regulatory authorities for a full explanation of these rights.
        </p>

        <p>
          Your fundamental rights under the GDPR include:
        </p>
        <ul>
          <li><p>The right to access</p></li>
          <li><p>The right to rectification</p></li>
          <li><p>The right to erasure</p></li>
          <li><p>The right to restrict processing</p></li>
          <li><p>The right to object to processing</p></li>
          <li><p>The right to data portability</p></li>
          <li><p>The right to complain to a supervisory authority</p></li>
          <li><p>The right to withdraw consent</p></li>
        </ul>

        <p>
          You have the right to confirm whether we process your personal data and, if so, to access your personal data along with certain additional information. This additional information includes details about the purposes of the processing, the categories of personal data concerned, and the recipients of the personal data. If the rights and freedoms of others are not affected, we will provide you with a copy of your personal data. The first copy will be provided free of charge, but additional copies may be subject to a reasonable fee.
        </p>

        <p>
          You have the right to correct any inaccurate personal data about you, and, taking into account the purposes of the processing, you have the right to complete any incomplete personal data about you.

In certain circumstances, you have the right to erase your personal data without undue delay. These circumstances include when the personal data are no longer necessary for the purposes for which they were collected or otherwise processed, you have withdrawn consent based on consent, the processing is for direct marketing purposes, and the personal data have been unlawfully processed. However, there are specific general exclusions from the right to erasure. These general exclusions include situations where processing personal data is necessary for exercising the right of freedom of expression and information, for compliance with a legal obligation, or for the establishment, exercise, or defense of legal claims.
        </p>

        <p>
          In certain circumstances, you have the right to restrict the processing of your personal data. These circumstances are when you contest the accuracy of personal data, processing is unlawful, but you oppose erasure, we no longer need the personal data for the purposes of processing, but you require personal data for the establishment, exercise, or defense of legal claims, and you have objected to processing pending the verification of that objection. If processing is restricted under this right, we may still store your personal data. However, other than with your consent, we will only process it: for the establishment, exercise, or defense of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.
        </p>

        <p>
          You have the right to object to the processing of your personal data based on your specific situation, but only if the legal basis for processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of legitimate interests pursued by us or a third party. If you make such an objection, we will cease to process your personal data unless we can demonstrate compelling legitimate grounds for the processing that override your interests, rights, and freedoms, or unless the processing is for the establishment, exercise, or defense of legal claims.
        </p>

        <p>
          You have the right to object to the processing of your personal data for purposes of direct marketing (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your personal data for this purpose.
        </p>

        <p>
          You have the right to object to the processing of your personal data for purposes of scientific or historical research or statistical research, on grounds relating to your particular situation, unless the processing is necessary for the performance of a task carried out for reasons of public interest.
        </p>

        <p>
          To the extent that the legal basis for our processing of your personal data is consent, and such processing is carried out by automated means, you have the right to receive your personal data from us in a structured, commonly used, and machine-readable format. However, this right does not apply if it would adversely affect the rights and freedoms of others.
        </p>

        <p>
          If you believe that our processing of your personal data violates data protection laws, you have the right to lodge a complaint with the supervisory authority responsible for data protection. You may do this in the EU member state of your habitual residence, your place of work, or the place of the alleged infringement.
        </p>

        <p>
          If the legal basis for our processing of your personal data is consent, you have the right to withdraw that consent at any time. Withdrawal of consent will not affect the lawfulness of processing before withdrawal.
        </p>

        <p>
          You can exercise your rights regarding your personal data through written notice sent to us
        </p>
        <br />

        <h5>7. THIRD-PARTY WEBSITES</h5>
        <p>
          Our website includes links and information about third-party websites. This Privacy Policy applies only to our websites, and we are not responsible for the privacy policies of third parties, even if we provide links to them. If you use any link on our website, we recommend reading the privacy policies of that website before sharing personal or financial data.
        </p>

        <p>
          We also manage several pages on social media. While this Policy covers how we will use data collected from those pages, it does not cover how social media providers use your information. Please read the privacy policies of social media platforms before sharing data and use privacy settings and login mechanisms to control how your data is used.
        </p>
        <br />

        <h5>8. CHILDREN'S PERSONAL DATA</h5>

        <p>
          Our website is intended for individuals who are not minors according to applicable laws. If we have reason to believe that we possess the personal data of an individual considered a minor, we will delete that personal data or take other appropriate measures.
        </p>
        <br />
        
        <h5>9. UPDATING INFORMATION</h5>

        <p>
          Please inform us if corrections or updates to the personal data we have about you are necessary.
        </p>
        <br />

        <h5>10. ACTING AS A DATA PROCESSOR</h5>
        
        <p>
          Regarding personal data collected through the websites of our clients, we do not act as the data controller; instead, we act as a data processor. To the extent we act as a data processor, and not as a data controller, this Policy does not apply. Our legal obligations as a data processor are instead governed by the contract between us and the relevant data controller.
        </p>
        <br />

        <h5>11. ABOUT COOKIES</h5>

        <p>
          A cookie is a file that contains an identifier (a string of letters and numbers) sent by a web server to a web browser and stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies can be "persistent" cookies or "session" cookies: a persistent cookie will be stored by the browser and will remain valid until the expiry date, unless deleted by the user before the expiry date; on the other hand, a session cookie will expire at the end of the user session when the web browser is closed. Cookies usually do not contain information that personally identifies a user, but personal information we store about you may be linked to information stored and obtained from cookies.
        </p>
        <br />

        <h5>12. COOKIES WE USE</h5>
        <p>
          The Adria Digital Exchange d.o.o. website uses the following cookies:
        </p>

        <table>
            <tr>
              <th>Cookie Name</th>
              <th>Used by</th>
              <th>Description</th>
              <th>Duration</th>
            </tr>
            <tr>
              <td>_ga and _gid</td>
              <td>Google Analytics</td>
              <td>Used to distinguish users on the website in Google Analytics</td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>__cfduid</td>
              <td>CloudFlare</td>
              <td>Identifies individual clients behind a shared IP address and applies security settings for each client</td>
              <td>365 days</td>
            </tr>
        </table>
        <br />

        <p>We use Google Analytics to analyze the usage of our website. Google Analytics collects information about website usage through cookies. The information collected regarding our website is used to generate reports on website usage. Google's privacy policy is 
          available at: <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/.</a>
        </p>
        <br />

        <h5>13. COOKIE MANAGEMENT</h5>
        
        <p>
          Most browsers allow you to reject the acceptance of cookies and delete existing cookies. Methods for doing so may vary from browser to browser and depend on the browser version. However, you can find updated information on blocking and deleting cookies through the following links:
        </p>

        <ul>
          <li><p>Chrome</p></li>
          <li><p>Firefox</p></li>
          <li><p>Opera</p></li>
          <li><p>Internet Explorer (up to 2022)</p></li>
          <li><p>Safari</p></li>
          <li><p>Edge</p></li>
        </ul>
        <br />

        <p>
          Blocking all cookies will have a negative impact on the usability of many websites. If you block cookies, you will not be able to use all the features of our websites.
        </p>

        <br />

        <h5>14. OUR DATA</h5>

        <p>
          This website is owned and operated by Adria Digital Exchange d.o.o. We are registered in Croatia under the registration number: 081510700 and tax identification number: 16372761404. Our registered address is Ulica grada Vukovara 271, Zagreb, Croatia 10000.
        </p>

        <p>
          You can contact us:
        </p>

        <ul>
          <li><p>By mail, using the provided postal address,</p></li>
          <li><p>By email, using the email address: contact@ade.hr</p></li>
        </ul>
        <br />

        <h5>15. YOUR ACCEPTANCE OF THIS POLICY</h5>

        <p>
          By using this website, you confirm your acceptance of this Policy. If you do not agree with this Policy, please do not use our website. Continued use of the website after the publication of changes to this Policy will be considered your acceptance of those changes.
        </p>

        <p>
          We may update this Policy from time to time by publishing a new version on our website. Please check this page periodically to stay informed about any changes to this Policy. We may notify you of changes to this Policy via email.
        </p>

        <br />



        <div class="close-button" @click="privacy = 1;">
          <div class="close-icon">
            <img src="@/assets/close-icon.png" alt="digital assembly" />
          </div>
          <p>Close</p>
        </div>
      </div>
    </div>

    <div class="doc-box doc-box-margin">
      <div class="doc-box-inner" @click="cookie = 2;">
        <p>Cookie Policy</p>
      </div>
      <div class="document-display" v-if="cookie === 2">

        <h4>COOKIE POLICY</h4>

        <h5>Cookies</h5>
        <p>
          This website needs to store a small amount of information (cookies) on your computer to function properly, enable further improvements on the website, and enhance your browsing experience. More than 90% of websites use cookies, but in accordance with the regulations of the European Union adopted on March 25, 2011, we are obliged to request your consent before storing cookies. By using this website, you agree to the use and storage of cookies. If you block cookies, you can still browse the website, but some of its features may not be available.
        </p>
        <br />

        <h5>What is a cookie?</h5>
        <p>
          In general, a cookie is information stored on your computer by the websites you visit. Cookies often store your preferences and settings for a website, such as the selected language or location. When you revisit the same website, your browser sends back cookies belonging to that site. This allows the website to display information tailored to your needs. Cookies can store various information, including personal data (such as your name or email). However, this information can only be stored with your permission – websites cannot access information you haven't provided and cannot access other files on your computer. By default, activities related to storing and sending cookies are not visible to you. However, you can change your browser settings to approve or reject requests to store cookies, automatically delete stored cookies when you close the browser, and more.
        </p>
        <br />

        <h5>How to disable cookies?</h5>
        <p>
          By disabling cookies, you decide whether to allow the storage of cookies on your computer. Cookie settings can be controlled and configured in your web browser. If you disable cookies, you may not be able to use some features of the websites you visit.
        </p>
        <br />

        <h5>What are temporary cookies?</h5>
        <p>
          Temporary or session cookies are deleted from your computer when you close the browser. Websites use them to store temporary information, such as items in your shopping cart.
        </p>
        <br />

        <h5>What are Persistent Cookies?</h5>
        <p>
          Persistent or stored cookies remain on your computer after you close the web browser. Websites use them to store information, such as usernames and passwords, so you don't have to log in every time you visit a specific website. Persistent cookies can stay on your computer for days, months, or even years.
        </p>
        <br />

        <h5>What are First-Party Cookies?</h5>
        <p>
          First-party cookies are sent from the visited website and can be either persistent or session cookies. Websites use these cookies to store information that will be reused during your next visit to that specific page.
        </p>
        <br />

        <h5>What are Third-Party Cookies?</h5>
        <p>
          Third-party cookies originate from advertisements on other websites (such as pop-ups or banners) present on the website you are currently browsing. Websites use these cookies to track your internet habits for marketing purposes.
        </p>
        <br />

        <h5>Does ade.hr uses a Cookies?</h5>
        <p>
          Yes, we primarily use cookies to enhance the user experience on our websites.
        </p>
        <br />

        <h5>What Types of Cookies does ade.hr uses and what is their purpose?</h5>
        <p>
          Session Cookies: These are temporary cookies that expire (and are automatically deleted) when you close the browser. We use session cookies to enable access to content and facilitate commenting (actions you need to take when logging in with personal data on the website).
        </p>
        <p>
          Persistent Cookies: Usually, these cookies have an expiration date set far into the future to remain on your browser until they expire or until you manually delete them. We use persistent cookies for functionalities like the "Stay signed in" checkbox, assisting users in accessing the website as registered users. Additionally, we use persistent cookies to better understand user habits and improve and customize the website based on your preferences. This information is anonymous – we do not see individual user data.
        </p>
        <br />

        <h5>Does this website uses third-party cookies?</h5>
        <p>
          The website uses several external services that store limited cookies on users' computers. These cookies are not implemented by this website but are used to enable the normal functioning of certain features, providing users with easy access to content. Currently, we enable:
        </p>
        <br />

        <h5>Engagement Measurement:</h5>
        <ul>
          <li><p>ade.hr uses Google Analytics to measure user engagement and visits.</p></li>
          <li><p>    • If you wish to prevent Google Analytics from storing cookies on your computer, visit the following link for opt-out options:
        ◦ Google Analytics – <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a></p></li>
        </ul>

        <h5>Additional Information on Disabling Cookies</h5>
        <p>
          Currently, there are several websites that allow you to stop the storage of cookies for various services. 
        </p>
        <br />

        <h5>More information is available at the following links:</h5>
        <ul>
          <li><p><a href="http://www.allaboutcookies.org/" target="_blank">http://www.allaboutcookies.org/</a></p></li>
          <li><p><a href="http://www.youronlinechoices.eu/" target="_blank">http://www.youronlinechoices.eu/</a></p></li>
          <li><p><a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a></p></li>
        </ul>
        <br />

        <h5>Additional Information on GDPR:</h5>
        <p>More information about the General Data Protection Regulation (GDPR) and its application to cookies is available here:</p>
        <a href="https://www.eugdpr.org/" target="_blank">https://www.eugdpr.org/</a>
        <br />
        <br />
        <br />



        <div class="close-button" @click="terms = 1;">
          <div class="close-icon">
            <img src="@/assets/close-icon.png" alt="digital assembly" />
          </div>
          <p>Close</p>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-inner">
        <div class="footer-half">
          <div class="footer-box">
            <div class="footer-logo">
              <img src="@/assets/logo-light.png" alt="ADE platform" />
            </div>
          </div>
          <div class="footer-box">
            <h5>Company Card</h5>
            <p><span>Short name:</span> Adria Digital Exchange LLC</p>
            <p><span>Competent court:</span> Commercial Court in Zagreb</p>
            <p><span>Registration number:</span> 081510700</p>
            <p><span>Identification number:</span> 16372761404</p>
            <p><span>IBAN:</span> HR4724120091139007126</p>
            <p><span>Board Members:</span> Tomislav Gračan, Saša Hunjak</p>
            <p>Regulated: <a
                href="https://www.hanfa.hr/podrucja-nadzora/virtualne-valute/registar-pruzatelja-usluga-virtualne-imovine/"
                target="_blank" class="hanfa">Croatian Financial services Supervisory Agency</a></p>
          </div>
        </div>
        <div class="footer-half">
          <div class="footer-box"></div>
          <div class="footer-box">
            <h5>Contact Us</h5>
            <p>Ulica grada Vukovara 271</p>
            <p>10 000, Zagreb</p>
            <p>Croatia</p>
            <a href="mailto: contact@ade.hr" class="email-link">E-Mail Us</a>

          </div>
        </div>
      </div>
      <div class="footer-copy">
        <p>© Copyright <span>Adria Digital Exchange</span>. All Rights Reserved</p>
        <p>2023.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',

  props: {
    msg: String
  },

  data() {
    return {
      pageSelect: 1,
      alidgeble: 1,
      terms: 1,
      privacy: 1,
      cookie: 1,
      sasa: 1,
      tomislav: 1,
      tickers: [],
      tickersWSConnection: null,
      tickersURL: "wss://ws-sandbox.ade.hr/ticker"
    }
  },

  mounted() {
    this.marketsTickersConnect()
  },

  methods: {

    openNav() {
      const sidenav = document.getElementById("sidenav");
      sidenav.classList.add("openNav");
    },

    closeNav() {
      const sidenav = document.getElementById("sidenav");
      sidenav.classList.remove("openNav");
    },

    setTickerPrefix(delta) {
      if (delta.toString().slice(0,1) == "-") {
        return "";
      }
      else {
        return "+";
      }
    },

    setTickerColor(delta) {
      if (delta.toString().slice(0,1) == "-") {
        return "negative";
      }
      else {
        return "positive";
      }
    },

    async marketsTickersConnect() {
      if (this.tickersWSConnection) {
        this.tickersWSConnection.close();
      }

      this.tickersWSConnection = new WebSocket(this.tickersURL);

      this.tickersWSConnection.addEventListener(
        "open",
        (event) => {
          console.log("WebSocket connection opened:", event.currentTarget.url);
        }
      );

      this.tickersWSConnection.addEventListener(
        "message",
        (event) => {
          let ticker = JSON.parse(event.data);

          let alreadyExists = false;
          this.tickers.map( // if the ticker is already exists then update it
            entry => {
              if (entry.market == ticker.market) {
                alreadyExists = true;
                entry.price = ticker.price;
                entry.delta = ticker.delta;
                entry.delta_decimal = ticker.delta_decimal;
              }
            }
          );

          let marketsPreSetOrder = [
            "BTCUSD",
            "ETHUSD",
            "LTCUSD",
            "BCHUSD",
            "PAXGUSD"
          ];

          marketsPreSetOrder.forEach(
            market => {
              if (alreadyExists == false && market == ticker.market) {
                this.tickers.push(ticker);
              }
            }
          );

          let orderedTickers = [];
          marketsPreSetOrder.forEach(
            market => {
              this.tickers.forEach(
                ticker => {
                  if (ticker.market == market) {
                    orderedTickers.push(ticker);
                  }
                }
              )
            }
          );

          orderedTickers.map(
            ticker => {
              if (ticker.market.length == 6) {
                ticker.crypto_name = ticker.market.slice(0, 3);
                ticker.fiat_name = ticker.market.slice(3, 6);
              }
              else if (ticker.market.length == 7) {
                ticker.crypto_name = ticker.market.slice(0, 4);
                ticker.fiat_name = ticker.market.slice(4, 7);
              }
            }
          );

          this.tickers = orderedTickers;
        }
      );

      this.tickersWSConnection.addEventListener(
        "close",
        (event) => {
          console.log("WebSocket connection closed:", event);
        }
      );

      this.tickersWSConnection.addEventListener(
        "error",
        (error) => {
          console.error("Message from server ", error);
        }
      );

      return;
    },
    setCurrencySymbol(currency) {
      try {
        return require("@/assets/crypto/" + currency + ".svg");
      }
      catch (error) {
        return require("@/assets/crypto/BTC.svg");
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../assets/Montserrat-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Montserrat-Regular', sans-serif;
}


.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin: 0px;
  color: #1A1A1A;
  position: relative;
}

.white {
  background-color: #fff;
}

#sidenav {
  height: 100vh;
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 99;
  transition: 0.5s;
  background-color: #fff;
  box-shadow: -6px 0px 6px #00968814;
  width: 100%;
  padding: 24px;
}

#sidenav.openNav {
  right: 0%;
}

.sidenav-links {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 48px;
}

.tickers-outer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tickers {
  width: 1000px;
  max-width: 1360px;
  margin: 24px 0px;
}

.tickers table {
  width: 100%;
  border-collapse: collapse;
}

.tickers table th,
.tickers table td {
  border: none;
}

.tickers table th {
  text-align: left;
}

.tickers table .right-moved {
  text-align: right;
}

.tickers table td,
.tickers table th {
  border-bottom: 1px solid #333;
  height: 120px;
}

.tickers table td.first-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tickers table td p.amount {
  width: 100%;
  font-size: 18px;
  color: #6A4AED;
}


.tickers table td.first-column .icon-holder {
  display: flex;
  justify-content: center;
  width: 50px;
  /* height: 40px; */
}

.tickers table td.first-column .icon {
  /* width: 40px; */
  height: 40px;
}

.tickers table td.first-column .crypto-name {
  font-size: 18px;
  color: #6A4AED;
  margin-right: 12px;
}

.positive {
  color: #4cb870;
}

.negative {
  color: #c04641;
}

.tickers table td.second-column {
  text-align: right;
}

.tickers table td.second-column p {
  padding-right: 12px;
}

.tickers table td.third-column {}

.tickers table td.third-column p {}

.ticker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  border-bottom: 1px solid #333;
  padding: 12px 0px;
  height: 200px;
}

.BCH_icon {
  transform: rotate(-30deg);
}

.currency {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.amount {
  font-size: 24px;
  color: #6A4AED;
}

.icon {
  height: 100px;
  width: auto;
  margin-right: 24px;
}

.stats {
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.companies-section-inner {
  flex-direction: column;
}

.company-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
}

.sidenav-links p {
  text-align: left;
  margin-bottom: 30px;
}

.mock-list {
  width: 70%;
  text-align: left;
  margin: 18px 0px;
}

.mock-list p {
  margin: 12px 0px;
}

.presentation-box-adria h4 {
  margin-bottom: 18px;
  font-size: 36px;
  color: #6A4AED;
  text-align: center;
}

.presentation-box-adria h4.left-moved {
  text-align: left;
  margin-bottom: 24px;
}

.presentation-box-adria p {
  font-size: 18px;
  text-align: justify;
  margin-bottom: 12px;
}

.intro {

  width: 100%;
  display: flex;
  justify-content: center;
}

.intro-2 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inner-intro {
  width: 1300px;
  max-width: 1360px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.inner-intro-2 {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 180px;
}

.menu {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 0px;
}

.menu-links {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.menu-links p {
  margin: 0px 18px;
  transition: 0.3s;
  cursor: pointer;
  font-weight: 700;
  color: #6A4AED;
}

.menu-links p:hover {
  color: #FF5C5C;
}

.menu-logo {
  width: 200px;
  height: auto;
}

.menu-logo img {
  width: 100%;
  height: 100%;
}

.mobile-menu-close {
  height: 40px;
  width: 40px;
  display: none;
}

.mobile-menu-close img {
  height: 100%;
  width: 100%;
}

.close-sidenav {
  width: 40px;
  height: 40px;
}

.close-sidenav img {
  width: 100%;
  height: 100%;
}

.intro-box-1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 160px;
  margin-bottom: 48px;
}

.intro-box-1-left {
  flex: 2;
  text-align: left;
  padding: 12px;
}

.intro-box-1-left h1 {
  font-size: 42px;
  font-weight: 400;
  margin-bottom: 30px;
  color: #6A4AED;
  width: 85%;
}

.intro-box-1-left h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  width: 75%;
  text-align: justify;
}

.intro-box-1-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-logo-box {
  flex: 1;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placholder-slika {
  height: 400px;
  width: 600px;
}

.placholder-slika img {
  width: 100%;
  height: 100%;
}

.intro-box-2 {
  width: 100%;
}

.intro-box-2-row-moto {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 48px;
}

.intro-box-2-row-moto h2 {
  color: #FF5C5C;
  font-size: 30px;
}

.intro-box-2-row-moto h2 span {
  color: #6A4AED;
}

.intro-box-2-row-1 {
  padding-left: 12px;
  margin-bottom: 24px;
}

.intro-box-2-row-1 p {
  font-size: 16px;
}

.intro-box-2-row-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.logo-placeholder {
  width: 200px;
  height: auto;
  transition: 0.3s;
}

.logo-placeholder img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  filter: grayscale(1);
}

.logo-placeholder:hover {
  scale: (2);
}

.presentation-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 45px;
}

.presentation-box-inner {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
}

.invering {
  flex-direction: row-reverse;
}

.presentation-box-inner-half {
  flex: 1;
}

.presentation-box-inner-half h4 {
  font-size: 42px;
  font-weight: 400;
  color: #6A4AED;
  margin-bottom: 24px;
}

.presentation-box-inner-half p {
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
  line-height: 24px;
}

.companies-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.companies-section-inner {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
}

.double-company {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.double-company-inner {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 0px;
}

.company-half {
  padding: 12px;
  flex: 1;
}

.company-half h4 {
  text-align: center;
  font-size: 30px;
  color: #6A4AED;
  margin-bottom: 24px;
}

.company-half p {
  text-align: justify;
  font-size: 18px;
}

.company-half-logo {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-half-logo .zse-slika {
  height: 200px;
  width: auto;
}

.company-half-logo .zse-slika img {
  width: 100%;
  max-width: 200px;

}

.company-half-logo .digital-slika {
  height: 120px;
  width: auto;
}

.company-half-logo .digital-slika img {
  width: 100%;
  max-width: 300px;
}

.company-box {
  padding: 12px;
}

.company-box h4 {
  margin-bottom: 18px;
  font-size: 36px;
  color: #6A4AED;
  text-align: center;
}

.company-box p {
  text-align: justify;
  font-size: 18px;
}

.middle-box {
  background-color: #E0E0E0;
  padding: 24px 0px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 84px;
}

.middle-box-2 {
  background-color: #fff;
  padding: 24px 0px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 84px;
}

.middle-box-inner {
  text-align: center;
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.middle-box-inner h3 {
  color: #6A4AED;
  font-size: 42px;
  margin-bottom: 18px;
}

.middle-box-inner p {
  font-size: 20px;
  font-weight: 400;
}

.middle-box-inner ul {
  list-style: none;
  text-align: center;
  margin-left: 30px;
  margin-top: 12px;
  margin-bottom: 30px;
}

.middle-box-inner ul li {
  margin: 12px 0px;
  font-size: 18px;
}

.middle-box-inner a {
  color: #6A4AED;
  transition: 0.3s;
  text-decoration: none;
}

.middle-box-inner a:hover {
  color: #FF5C5C;
}

.usluge-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 240px;
}

.usluge-row-inner {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 48px;
}

.usluge-box {
  flex: 1;
  height: 200px;
  padding: 18px 6px;
}

.usluge-row-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.usluge-box-inner {
  padding: 12px;
  box-shadow: -3px -3px 6px rgba(0, 0, 0, 0.1), 6px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 310px;
}

.usluge-box-inner h4 {
  font-size: 24px;
  color: #6A4AED;
  font-weight: 400;
  text-align: center;
  width: 70%;
}

.usluge-box-inner p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}

.usluge-box-inner h4.move-down {
  margin-bottom: 38px;
}

.usluge-icon {
  height: 60px;
  width: 60px;
}

.usluge-icon img {
  width: 100%;
  height: 100%;
}

.podnaslov {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.podnaslov-inner {
  width: 1300px;
  max-width: 1360px;
}

.podnaslov-inner h4 {
  font-size: 42px;
  font-weight: 400;
  color: #6A4AED;
  margin-bottom: 36px;
}

.firma-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.firma-box-inner {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.firma-pic {
  width: 80%;
  height: 400px;
  background-color: aqua;
}

.doc-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doc-box-inner {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 0px;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.1), 5px 5px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 36px;
}

.doc-box-margin {
  margin-bottom: 48px;
}

.doc-box-inner p {
  font-size: 18px;
  font-weight: 500;
}

.document-display ul {
  margin-left: 24px;
}

.document-display h4 {
  margin-bottom: 24px;
}

.document-display h5 {
  margin-bottom: 18px;
}

.document-display {
  width: 1300px;
  max-width: 1360px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1), 3px 3px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 36px;
}

.document-display p {
  margin-bottom: 12px;
}

.document-display table,
th,
td {
  border: 1px solid #333;
  border-collapse: collapse;
  padding: 6px;
}

.placeholder-bigpic-1 {
  max-width: 400px;
  height: auto;
}

.placeholder-bigpic-2 {
  height: 400px;
  width: auto;
  margin-bottom: 24px;
}

.placeholder-bigpic-2 img {
  width: 100%;
  height: 100%;
}

.placeholder-bigpic-3 {
  height: 200px;
  width: auto;
  margin-bottom: 24px;
}

.placeholder-bigpic-3 img {
  width: 100%;
  height: 100%;
}

.placeholder-bigpic-1 img {
  width: 100%;
  height: 100%;
}

.placeholder-bigpic-adria {
  max-width: 400px;
  height: auto;
}

.placeholder-bigpic-adria img {
  width: 100%;
  height: 100%;
}

.placeholder-bigpic-zse {
  max-width: 400px;
  height: auto;
}

.placeholder-bigpic-zse img {
  width: 100%;
  height: 100%;
}

.to-right {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-button p {
  margin-bottom: 0px;
}

.close-icon {
  height: 20px;
  width: 18px;
}

.close-icon img {
  width: 100%;
  height: 100%;
}

.team-member {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.team-member button {
  cursor: pointer;
  padding: 12px 48px;
  background-color: #6A4AED;
  color: #fff;
  transition: 0.3s;
  border-radius: 90px 0% 90px 0%;
}

.team-member button:hover {
  background-color: #A278FF;
}

.team-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.team-member h4 {
  font-size: 24px;
  color: #6a4aed;
  margin-bottom: 6px;
}

.team-member h5 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 18px;
}

.team-pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.team {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 48px;
}

.team-inner {
  width: 866px;
  max-width: 1360px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.linkedin-icon {
  width: 40px;
  height: 40px;
  transition: 0.3s;
}

.linkedin-icon img {
  width: 100%;
  height: 100%;
}

.linkedin-icon:hover {
  filter: grayscale(1);
}

.links-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 0px 12px;
  margin-bottom: 48px;
}

.links-section-inner {
  width: 1300px;
  max-width: 1360px;
}

.links-section-inner h4 {
  font-size: 42px;
  font-weight: 400;
  color: #6A4AED;
  margin-bottom: 24px;
}

.links-section-inner p {
  margin-top: 12px;
  font-size: 20px;
  font-weight: 400;
}

.links-section-inner a {
  color: #6A4AED;
  text-decoration: none;
  transition: 0.3s;
  font-size: 20px;
  font-weight: 400;
}

.links-section-inner a:hover {
  color: #FF5C5C;
}

.center-image {
  display: flex;
  justify-content: center;
}

.footer {
  width: 100%;
  background-color: #1A1A1A;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 0px 12px;
}

.about-cv {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  padding: 24px;
}

.about-cv-inner {
  height: 90%;
  width: 100%;
  overflow-y: scroll;
}

.about-cv-inner h4 {
  margin-bottom: 12px;
}

.about-cv-inner p {
  text-align: justify;
  margin-bottom: 12px;
}

.cv-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px;
}

.close-cv {
  height: 16px;
  width: 16px;
}

.break {
  word-break: break-all;
}

.close-cv img {
  height: 100%;
  width: 100%;
}

.footer-inner {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  margin-bottom: 24px;
}

.footer-half {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.footer-box {
  flex: 1;
  color: #E0E0E0;
}

.footer-logo {
  width: 160px;
  height: auto;
}

.footer-logo img {
  width: 100%;
  height: 100%;
}

.footer-box h5 {
  font-size: 18px;
  margin-bottom: 18px;
}

.footer-box p {
  margin-bottom: 6px;
  font-size: 14px;
}

.footer-box p span {
  font-weight: 700;
}

.footer-copy {
  width: 1300px;
  max-width: 1360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #E0E0E0;
}

.footer-copy p span {
  color: #FF5C5C;
}

a.hanfa {
  text-decoration: none;
  color: #A278FF;
}

.email-link {
  text-decoration: none;
  color: #6A4AED;
  transition: 0.3s;
}

.email-link:hover {
  color: #FF5C5C;
}

@media(max-width: 1300px) {

  .inner-intro {
    height: 100%;
    width: 100%;
  }

  .intro-box-1 {
    flex-direction: column;
    margin-top: 80px;
  }

  p.partners {
    text-align: center;
  }

  .intro-box-1-left {
    flex: 1;
    text-align: center;
  }

  .intro-box-1-left h2 {
    width: 100%;
  }

  .intro-box-1-right {
    width: 100%;
  }

  .intro-box-2-row-2 {
    flex-direction: column;
  }

  .ticker {
    padding-left: 12px;
    padding-right: 12px;
  }

  .footer-inner {
    width: 100%;
  }

  .footer-copy {
    width: 100%;
  }

  .presentation-box-inner {
    width: 100%;
    flex-direction: column;
  }

  .usluge-row-inner {
    width: 100%;
    flex-direction: column;
  }

  .logo-placeholder {
    margin-bottom: 30px;
  }

  .doc-box {
    padding: 0px 12px;
  }

  .doc-box-inner {
    width: 100%;
  }

  .document-display {
    width: 100%;
  }

  .links-section {
    padding: 0px 12px;
  }

  .links-section-inner {
    width: 100%;
  }

  .links-section-inner h4 {
    text-align: center;
  }

  .podnaslov-inner {
    width: 100%;
  }

  .podnaslov-inner h4 {
    text-align: center;
  }
}

@media (max-width: 1199.98px) {

  .intro {
    padding: 0px 12px;
  }

  .intro-box-1 {
    margin-top: 100px;
  }

  .intro-box-1-left h1 {
    width: 100%;
  }

  .presentation-box {
    padding: 0px 24px;
  }

  .presentation-box-inner-half h4 {
    text-align: center;
  }

  .middle-box {
    padding: 12px 24px;
  }

  .usluge-box-inner h4 {
    width: 90%;
  }

  .placeholder-bigpic-1 {
    margin-bottom: 24px;
  }

  .usluge-row {
    margin-bottom: 72px;
    padding: 0px 24px;
  }

  .placeholder-bigpic-adria,
  .placeholder-bigpic-zse {
    margin-bottom: 30px;
  }

  .intro-2 {
    padding: 0px 12px;
  }

  .podnaslov {
    padding: 0px 12px;
  }

  .footer {
    padding: 12px 24px;
  }
}

@media (max-width: 991.98px) {

  .menu-logo {
    width: 150px;
    height: auto;
  }

  .intro-box-1-left h1,
  .presentation-box-inner-half h4,
  .middle-box-inner h3,
  .links-section-inner h4,
  .podnaslov-inner h4 {
    font-size: 36px;
  }

  .intro-box-1-left h2,
  .presentation-box-inner-half p,
  .middle-box-inner p,
  .links-section-inner p,
  .links-section-inner a {
    font-size: 18px;
  }

  .middle-box {
    padding: 24px;
  }

  .double-company-inner {
    flex-direction: column;
  }

  .double-company-inner p {
    padding: 0px 12px;
  }
}

@media (max-width: 767.98px) {
  .mobile-menu-close {
    display: block;
  }

  .menu-links {
    display: none;
  }

  .placholder-slika {
    width: 400px;
    height: auto;
  }

  .ticker {
    flex-direction: column;
    height: 240px;
  }

  .currency {
    margin-bottom: 24px;
  }

  .stats {
    width: 100%;
  }

  .team-inner {
    flex-direction: column;
  }

  .team-member {
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .footer-half {
    flex-direction: column;
    margin-bottom: 18px;
  }

  .footer-inner {
    flex-direction: column;
  }

  .footer-box {
    margin-bottom: 18px;
  }

  .footer-copy {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 12px;
  }

  .footer-copy p {
    font-size: 14px;
    margin-bottom: 12px;
  }
}

@media (max-width: 575.98px) {
  .usluge-box-inner {
    height: 420px;
  }
}
</style>
