<template>
  
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--regular);
}

html, body {
    display: block;
    width: 100%;
    height: 100%;
}
</style>
